import { Box, Button, Grid } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField } from '../../../component/form/FormTextField';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { FormDatePicker } from '../../../component/form/FormDatePicker';
import { FormDropdown, OptionItem } from '../../../component/form/FormDropdown';
import { FormEvent } from 'react';
import RestHttpClient from '../../../common/RestHttpClient';
import { optionalDate } from '../../../common/validation/Validations';

interface Props {
  toggleBasicSearch: Dispatch<SetStateAction<boolean>>;
  setSearchValues: Dispatch<SetStateAction<DealAdvancedSearchFormValues>>;
}

const schema = z.object({
  id: z.string().optional(),
  salesPerson: z.string().optional(),
  trademark: z.string().optional(),
  dealFrom: optionalDate,
  created: optionalDate,
  customer: z.string().optional(),
  model: z.string().optional(),
  hasFinancing: z.boolean().default(false),
  hasInsurance: z.boolean().default(false),
  hasSubmitted: z.boolean().default(false),
});

export type DealAdvancedSearchFormValues = z.infer<typeof schema>;

export function DealSearchAdvanced({ toggleBasicSearch, setSearchValues }: Props) {
  const { t } = useTranslation();

  const [trademarks, setTradeMarks] = useState<OptionItem[]>([]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<DealAdvancedSearchFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      hasInsurance: false,
      hasFinancing: false,
    },
  });

  useEffect(() => {
    RestHttpClient.getTrademarks(undefined).then((res) =>
      setTradeMarks(
        res.data.map(
          (trademark) =>
            ({
              label: trademark.name,
              value: trademark.name,
            } as OptionItem)
        )
      )
    );
    // eslint-disable-next-line
  }, []);

  const onSubmit = (event: FormEvent) =>
    handleSubmit(async (values) => {
      setSearchValues(values);
    })(event).catch((_) => {});

  const handleToggle = () => {
    toggleBasicSearch(true);
  };

  return (
    <div>
      <form onSubmit={onSubmit} noValidate>
        <Box className="search-panel search-header">
          <Grid spacing={2} container>
            <Grid item container spacing={4} md={11}>
              <Grid item container md={3}>
                <FormTextField control={control} name="id" label={t('search.id')} withIcon={true} />
                <FormTextField
                  control={control}
                  name="salesPerson"
                  label={t('search.salesPerson')}
                  withIcon={true}
                />
              </Grid>
              <Grid item container md={3}>
                <FormDropdown
                  control={control}
                  options={trademarks}
                  name="trademark"
                  label={t('search.trademark')}
                />
                <FormTextField
                  control={control}
                  name="model"
                  label={t('search.model')}
                  withIcon={true}
                />
              </Grid>
              <Grid item container md={3}>
                <FormDatePicker
                  dark
                  control={control}
                  name="dealFrom"
                  label={t('search.dealFrom')}
                />
                <FormDatePicker
                  dark
                  control={control}
                  name="created"
                  label={t('search.dealCreated')}
                />
              </Grid>
              <Grid item container md={3}>
                <FormTextField
                  control={control}
                  name="customer"
                  label={t('search.customer')}
                  withIcon={true}
                />

                <FormCheckbox
                  control={control}
                  name="hasFinancing"
                  label={t('search.hasFinancing')}
                />
                <FormCheckbox
                  control={control}
                  name="hasInsurance"
                  label={t('search.hasInsurance')}
                />

                <FormCheckbox
                  control={control}
                  name="hasSubmitted"
                  label={t('search.hasSubmitted')}
                />
              </Grid>
            </Grid>
            <Grid item md={1}>
              <Button variant="contained" fullWidth type="submit" disabled={isSubmitting}>
                {t('search.start')}
              </Button>
              <Button
                variant="text"
                className="toggle-search"
                onClick={handleToggle}
                fullWidth
                disabled={isSubmitting}
              >
                {t('search.basic')}
              </Button>
              <Button
                className="reset"
                variant="text"
                onClick={() => reset({ hasFinancing: false, hasInsurance: false })}
                fullWidth
                disabled={isSubmitting}
              >
                {t('reset')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
