/* eslint-disable eqeqeq */
import { Box, FormLabel, Grid, Stack, Typography } from '@mui/material';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import { CommercialCustomerFormValues } from '../../data/CommercialCustomerFormValues';
import { Control } from 'react-hook-form';
import { OptionItem } from '../../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import { FormTextField } from '../../../../component/form/FormTextField';
import { FormDatePicker } from '../../../../component/form/FormDatePicker';
import { FormRadioButton } from '../../../../component/form/FormRadioButton';
import { FormCheckbox } from '../../../../component/form/FormCheckbox';
import { useWatch } from 'react-hook-form';
import { tranformIban } from '../../../../common/validation/Validations';

interface Props {
  control: Control<CommercialCustomerFormValues>;
  legalForms: OptionItem[];
  countries: OptionItem[];
  branches: OptionItem[];
  pepSpecifications: OptionItem[];
  readonly: boolean;
  isNew?: boolean;
  showSelfDisclosure?: boolean;
}

export function EntryContact({
  control,
  legalForms,
  countries,
  branches,
  pepSpecifications,
  isNew,
  readonly,
  showSelfDisclosure,
}: Props) {
  const { t } = useTranslation();

  const ksvId = useWatch({ control, name: 'ksvId' });
  const gridCol = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  };

  const additionalPhoneNumber = useWatch({ control, name: 'additionalPhoneNumber' });
  const dataInformationSheetTransferred = useWatch({
    control,
    name: 'dataInformationSheetTransferred',
  });

  return (
    <Grid container spacing={4} className="entry-contact">
      <Grid item {...gridCol}>
        <FormDropdown
          control={control}
          options={legalForms}
          label={t('customers.edit.entryContact.legalForm')}
          name="legalFormId"
          required
          disabled={readonly}
        />
        <FormTextField
          control={control}
          label={t('customers.edit.entryContact.companyName') + ' **'}
          name="companyName"
          disabled={readonly}
        />

        {ksvId && (
          <FormTextField
            control={control}
            label={t('customers.edit.entryContact.ksvId')}
            name="ksvId"
            disabled={true}
          />
        )}
      </Grid>

      <Grid item {...gridCol}>
        <FormTextField
          control={control}
          label={t('customers.edit.entryContact.addressStreet')}
          name="addressStreet"
          disabled={ksvId != undefined || readonly}
          required
        />

        <FormTextField
          control={control}
          label={t('customers.edit.entryContact.addressHouseNumber')}
          name="addressHouseNumber"
          disabled={ksvId != undefined || readonly}
          required
        />
        <FormTextField
          control={control}
          label={t('customers.edit.entryContact.addressPostalCode')}
          name="addressPostalCode"
          transform={(s) => s.replace(/\D/g, '')}
          disabled={ksvId != undefined || readonly}
          required
        />
        <FormTextField
          control={control}
          label={t('customers.edit.entryContact.addressCity')}
          name="addressCity"
          disabled={ksvId != undefined || readonly}
          required
        />

        <FormDropdown
          control={control}
          name="addressCountryId"
          label={t('customers.edit.entryContact.addressCountry')}
          options={countries}
          required
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="phoneNumber"
          label={t('customers.edit.entryContact.phoneNumber')}
          disabled={readonly}
          required={
            additionalPhoneNumber === null ||
            additionalPhoneNumber === undefined ||
            additionalPhoneNumber === ''
          }
        />

        <FormTextField
          control={control}
          name="additionalPhoneNumber"
          label={t('customers.edit.entryContact.mobileNumber')}
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="emailAddress"
          label={t('customers.edit.entryContact.emailAddress')}
          disabled={readonly}
          required
        />
      </Grid>

      <Grid item {...gridCol}>
        <FormDropdown
          control={control}
          name="pepSpecificationId"
          label={t('customers.edit.personalData.pepSpecification')}
          options={pepSpecifications}
          disabled={readonly}
          required
        />

        <FormDatePicker
          control={control}
          name="foundationDate"
          label={t('customers.edit.entryContact.foundationDate')}
          disabled={readonly}
        />
        <FormTextField
          control={control}
          name="commercialRegisterNumber"
          label={t('customers.edit.entryContact.commercialRegisterNumber')}
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="uidNumber"
          label={t('customers.edit.entryContact.uidNumber')}
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="branchId"
          label={t('customers.edit.entryContact.branch')}
          options={branches}
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="companyBusiness"
          label={t('customers.edit.entryContact.companyBusiness')}
          disabled={readonly}
        />
        <Stack className="risk-increase" flexDirection="column" marginTop="1em" marginLeft={1}>
          <Typography variant="h5">
            {t('customers.edit.entryContact.riskIncreasingCircumstances')}
          </Typography>
          <FormRadioButton
            control={control}
            name="riskIncreasingCircumstances"
            label={t('customers.edit.entryContact.riskIncreaseOffShore')}
            disabled={readonly}
            defaultValue={false}
          />
          <Typography variant="body1">
            {t('customers.edit.entryContact.riskIncreaseAlternative')}
          </Typography>
        </Stack>
      </Grid>

      <Grid item {...gridCol} className="split-column">
        <Box>
          <FormTextField
            control={control}
            name="bankAccountIBAN"
            label={t('customers.edit.entryContact.bankAccountIBAN')}
            disabled={readonly}
            transform={tranformIban}
          />

          <FormTextField
            control={control}
            name="bankAccountBIC"
            label={t('customers.edit.entryContact.bankAccountBIC')}
            disabled={readonly}
          />
        </Box>

        <Box className="consent-form">
          <Box>
            <FormLabel>{t('customers.edit.entryContact.marketingConsent')}</FormLabel>
            <FormRadioButton
              required
              control={control}
              name="emailMarketingAggreed"
              label={t('customers.edit.entryContact.emailMarketingAggreed')}
              disabled={readonly}
            />
            <FormRadioButton
              required
              control={control}
              name="phoneMarketingAggreed"
              label={t('customers.edit.entryContact.phoneMarketingAggreed')}
              disabled={readonly}
            />
            <FormRadioButton
              required
              control={control}
              name="postalMarketingAggreed"
              label={t('customers.edit.entryContact.postalMarketingAggreed')}
              disabled={readonly}
            />
          </Box>

          {(isNew || !dataInformationSheetTransferred) && (
            <FormCheckbox
              control={control}
              name="dataInformationSheetTransferred"
              label={t('customers.edit.entryContact.dataInformationSheetTransferred') + '*'}
              disabled={readonly}
            />
          )}

          {showSelfDisclosure && (
            <FormCheckbox
              control={control}
              name="selfDisclosureAgreed"
              label={t('customers.edit.selfDisclosureAgreed')}
              labelSx={{ whiteSpace: 'pre-line' }}
              disabled={readonly}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
