import React, { createContext } from 'react';
import { GlobalTextView, NotificationView } from '../../generated/ApiClient';
import RestHttpClient from '../RestHttpClient';
import { useQuery } from 'react-query';

export const storageKey = 'dekato:messages:read';

interface GlobalState {
  notifications: NotificationView[];
  textItems: GlobalTextView[];
  isNotificationsFetched: boolean;
}

export const GlobalContext = createContext<GlobalState>({
  notifications: [],
  textItems: [],
  isNotificationsFetched: false,
});

export function GlobalProvider({ children }: { children: React.ReactNode }) {
  const fetchNotifications = async () => {
    const res = await RestHttpClient.notification();
    return res.data;
  };

  const fetchText = async () => {
    const res = await RestHttpClient.getGlobalText();
    return res.data;
  };

  const { data: notifications = [], isFetched: isNotificationsFetched } = useQuery<
    NotificationView[]
  >({
    queryKey: 'notifications',
    queryFn: fetchNotifications,
    staleTime: Infinity,
  });

  const { data: textItems = [] } = useQuery<GlobalTextView[]>({
    queryKey: 'textItems',
    queryFn: fetchText,
    staleTime: Infinity,
  });

  return (
    <GlobalContext.Provider
      value={{
        notifications,
        textItems,
        isNotificationsFetched,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
