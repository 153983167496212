import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { t } from 'i18next';
import { useState, useEffect, useMemo } from 'react';
import RestHttpClient from '../../../../common/RestHttpClient';
import { OptionItem } from '../../../../generated/ApiClient';

export function SalesPersonSelection({
  handleChanged,
  selected,
}: {
  handleChanged: (event: React.SyntheticEvent, value: any) => void;
  selected?: number;
}) {
  const [salesPersons, setSalesPersons] = useState<OptionItem[]>([]);
  const [loading, setLoadding] = useState(false);

  useEffect(() => {
    setLoadding(true);
    RestHttpClient.getSalespersons()
      .then((x) => setSalesPersons(x.data))
      .finally(() => setLoadding(false));
  }, []);

  const selectedValue = useMemo(
    () => salesPersons.find((v) => v.value === selected),
    [salesPersons, selected]
  );

  return (
    <>
      {loading && <LinearProgress />}
      {salesPersons.length > 0 ? (
        <Autocomplete
          key="filled"
          options={salesPersons}
          getOptionLabel={(option) => {
            return option.label;
          }}
          renderInput={(params) => (
            <TextField {...params} label={t('deals.new.seller')} variant="standard" required />
          )}
          onChange={handleChanged}
          noOptionsText="-"
          value={selectedValue}
        />
      ) : (
        <Autocomplete
          key="empty"
          options={[]}
          renderInput={(params) => (
            <TextField {...params} label={t('deals.new.seller')} variant="standard" required />
          )}
          noOptionsText="-"
          disabled
        />
      )}
    </>
  );
}
