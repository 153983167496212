import Drawer from '@mui/material/Drawer';
import { FormEvent, useEffect, useState, SyntheticEvent, Dispatch, SetStateAction } from 'react';
import { Tab, Tabs, Box, Grid, LinearProgress } from '@mui/material';
import { FinancesCalculation } from './finances-calculation/FinancesCalculation';
import { FinancesServices } from './finances-services/FinancesServices';
import { TabPanel, a11yProps } from '../../../../component/TabPanel';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FinancesCalculationFooter } from './FinancesCalculationFooter';
import { FinancesObject } from './finances-object/FinancesObject';
import {
  ApiconPriceFormUiState,
  ApiconServiceBundle,
  ApiconSpecialCasePriceFormState,
  ApiconSpecialCaseRequestBundle,
  CalculatedProduct,
  CalculateServicePriceRequest,
  CompleteSpecialCasePriceRequest,
  CreateSpecialCasePriceRequest,
  DropdownCollection,
  ExtendedConfiguration,
  Fee,
  FundingProductView,
  GetCalculationResponse,
  GetCalculationResponseForReconsideredProduct,
  LimitViolationVoting,
  ObjectConfigurationResponse,
  OptionItem,
  SalesAidLimitViolationView,
  SalesAidView,
  SaveProposalRequest,
  SaveServiceVariantRequest,
  ServiceConfigurationResponse,
  ServiceOption,
  SubmittedDealOverviewIPagedList,
  SubsidyView,
} from '../../../../generated/ApiClient';
import { renderValidationErrors } from '../../../../common/RestHttpClient';
import i18n from '../../../../common/i18n/i18n';
import {
  createCommonServiceRequest,
  mapSalesAidValuesToView,
} from '../../new/state/stateToDtoMapper';
import {
  schema,
  AgentOptionValues,
  FinancesCalculationContextValues,
  FinancesDrawerFormValues,
  RecalculateParams,
  SalesAidValues,
  ConversionOptionValues,
  CoOptIntermFile,
  ServiceValues,
  SpecialCaseValues,
} from './RightDrawerSchema';
import RestHttpClient from '../../../../common/RestHttpClient';
import { FinancesNova } from './finances-agentOptions/FinancesNova';
import { confirmHandler } from '../../../../common/ConfirmModal';
import { customErrorHandler } from '../../../../common/validation/CustomErrorHandler';
import { OptionItem as FormDropDownOptionItem } from '../../../../component/form/FormDropdown';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { ServiceVariant } from './finances-services/FinancesServiceVariant';

interface RightDrawerProps {
  dealId?: number;
  modelId?: number;
  distributionChannel?: number;
  dealTypeId?: number;
  vehicleCondition?: number;
  calculationId?: number;
  setCalculationId?: Dispatch<SetStateAction<number | undefined>>;
  isDrawerOpened: boolean;
  editObject?: FinancesCalculationContextValues;
  setIsDrawerOpened: Dispatch<SetStateAction<boolean>>;
  isIncludingVat: boolean;
  defaultValues?: any;
  fetchDefault?: (calculationId?: number) => Promise<GetCalculationResponse>;
  dealSalesAids: SalesAidValues[];
  onAddSalesAid: (salesAid: SalesAidValues) => void;
  onEditSalesAid: (salesAid: SalesAidValues, index: number) => void;
  fetchCalculate: (recalcParams: RecalculateParams) => Promise<GetCalculationResponse>;
  fetchReconsideredFundingProduct: (
    referenceDealId: number,
    overtakeCustomer: boolean
  ) => Promise<GetCalculationResponseForReconsideredProduct>;
  onSubmit: (
    values: FinancesDrawerFormValues | FinancesCalculationContextValues,
    isServiceVariantSaveRequest: boolean,
    technicalOptions: ServiceOption[],
    intermFiles: CoOptIntermFile[],
    isReload?: boolean
  ) => Promise<number | undefined>;
  readonly?: boolean;
  fetchSubmittedDeals: (
    page?: number,
    id?: string,
    salesPersonName?: string,
    creationDate?: Date,
    customerName?: string,
    orderSelectors?: string[]
  ) => Promise<SubmittedDealOverviewIPagedList>;
  onVote?: (vote: LimitViolationVoting) => void;
  hasSubmittedCalculation?: boolean;
}

export const FinancesRightDrawer = ({
  dealId,
  modelId,
  distributionChannel,
  dealTypeId,
  vehicleCondition,
  calculationId,
  setCalculationId,
  isDrawerOpened,
  setIsDrawerOpened,
  editObject,
  isIncludingVat,
  defaultValues,
  fetchDefault,
  fetchCalculate,
  onSubmit,
  dealSalesAids,
  onAddSalesAid,
  onEditSalesAid,
  readonly,
  fetchSubmittedDeals,
  fetchReconsideredFundingProduct,
  onVote,
  hasSubmittedCalculation,
}: RightDrawerProps) => {
  const [calcNum, setCalcNum] = useState<string | undefined>(undefined);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [serviceVariantsLoading, setServiceVariantsLoading] = useState(false);
  const [tabErrors, setTabErrors] = useState<number[]>([]);
  const [apiErrors, setApiErros] = useState<any>(undefined);

  const [financingTypes, setFinancingTypes] = useState<OptionItem[]>([]);
  const [mileages, setMileages] = useState<OptionItem[]>([]);
  const [interestCalcMethods, setInterestCalcMethods] = useState<OptionItem[]>([]);
  const [rateCategories, setRateCategories] = useState<OptionItem[]>([]);
  const [fundSources, setFundSources] = useState<OptionItem[]>([]);
  const [businessRelationships, setBusinessRelationships] = useState<OptionItem[]>([]);
  const [residualValueContracts, setResidualValueContracts] = useState<OptionItem[]>([]);
  const [specialOffers, setSpecialOffers] = useState<SalesAidView[]>([]);
  const [salesAids, setSalesAids] = useState<SalesAidView[]>([]);
  const [dealers, setDealers] = useState<OptionItem[]>([]);
  const [suppliers, setSuppliers] = useState<OptionItem[]>([]);
  const [hasDeposit, setHasDeposit] = useState<boolean>(false);
  const [hasClosingBalance, setHasClosingBalance] = useState<boolean>(false);
  const [hasResidualValue, setHasResidualValue] = useState<boolean>(false);
  const [isFinancingLeasing, setIsFinancingLeasing] = useState<boolean>(false);
  const [isDepositReadonly, setIsDepositReadonly] = useState<boolean>(true);
  const [isDownPaymentReadonly, setIsDownPaymentReadonly] = useState<boolean>(true);
  const [isResidualValueReadonly, setIsResidualValueReadonly] = useState<boolean>(true);
  const [isResidualValueConversionOptionsReadonly, setIsResidualValueConversionOptionsReadonly] =
    useState<boolean>(true);
  const [isAccountOwnerRequired, setIsAccountOwnerRequired] = useState<boolean>(true);
  const [showSourceOfFunds, setShowSourceOfFunds] = useState<boolean>(true);
  const [isLeasing, setIsLeasing] = useState<boolean>(false);
  const [isCredit, setIsCredit] = useState<boolean>(false);
  const [vatFree, setVatFree] = useState<boolean>(false);
  const [fuelServiceProducts, setFuelServiceProducts] = useState<OptionItem[]>([]);
  const [isFuelFinancingType, setIsFuelFinancingType] = useState<boolean>(false);
  const [isDurationReadonly, setIsDurationReadonly] = useState<boolean>(false);
  const [isMileageReadonly, setIsMileageReadonly] = useState<boolean>(false);
  const [isServiceDurationReadonly, setIsServiceDurationReadonly] = useState<boolean>(false);
  const [isServiceMileageReadonly, setIsServiceMileageReadonly] = useState<boolean>(false);
  const [isServiceVariantsResetRequired, setIsServiceVariantsResetRequired] =
    useState<boolean>(false);
  const [isServiceReadonly, setIsServiceReadonly] = useState<boolean>(false);
  const [serviceProducts, setServiceProducts] = useState<FormDropDownOptionItem[]>([]);
  const [productIndusties, setProductIndusties] = useState<FormDropDownOptionItem[]>([]);
  const [productUsages, setProductUsages] = useState<FormDropDownOptionItem[]>([]);
  const [campaigns, setCampaigns] = useState<FormDropDownOptionItem[]>([]);
  const [productOptions, setProductOptions] = useState<ServiceOption[]>([]);
  const [technicalOptions, setTechnicalOptions] = useState<ServiceOption[]>([]);
  const [serviceVariants, setServiceVariants] = useState<ServiceVariant[]>([]);
  const [initiallyReconsidered, setInitiallyReconsidered] = useState<boolean>(false);
  const [isCalcValid, setIsCalcValid] = useState(true);
  const [isVote, setIsVote] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isDirectInkassoVisible, setIsDirectInkassoVisible] = useState<boolean>(false);

  const [residualValueVehicleAbsoluteOld, setResidualValueVehicleAbsoluteOld] = useState<
    number | undefined
  >();
  const [residualValueConversionOptionsOld, setResidualValueConversionOptionsOld] = useState<
    number | undefined
  >();
  const [residualValuePercentageOld, setResidualValuePercentageOld] = useState<
    number | undefined
  >();
  const [displayGross, setDisplayGross] = useState<boolean>(false);

  const resetOldValues = () => {
    setTabErrors([]);
    setResidualValueVehicleAbsoluteOld(undefined);
    setResidualValuePercentageOld(undefined);
    setResidualValueConversionOptionsOld(undefined);
    setIsReload(false);
    setIsVote(false);
    setIsCalcValid(true);
    setIsDirectInkassoVisible(false);
  };

  const [intermFiles, setIntermFiles] = useState<CoOptIntermFile[]>([]);

  const [subsidies, setSubsidies] = useState<SubsidyView[]>([]);
  const [violations, setViolations] = useState<SalesAidLimitViolationView[]>([]);
  const [fees, setFees] = useState<Fee[]>([]);

  const { t } = useTranslation();

  const { control, reset, setValue, handleSubmit, setError, getValues, trigger, watch } =
    useForm<FinancesDrawerFormValues>({
      resolver: zodResolver(schema),
      defaultValues: { ...defaultValues, newDealSalesAid: [] },
      mode: 'onChange',
    });

  const financingTypeId = watch('financingTypeId');
  const serviceVariantId = watch('service.serviceVariantId');

  const setDropdownsOptions = (
    {
      financingTypes,
      interestCalculationMethods,
      rateCategories,
      businessRelationships,
      residualValueContracts,
      milages,
      sourceOfFunds,
      specialOffers,
      salesAids,
      dealers,
      fuelServiceProducts,
    }: DropdownCollection,
    skipSalesAids = false
  ) => {
    setFinancingTypes(financingTypes);
    interestCalculationMethods = translateInterestCalculationMethods(interestCalculationMethods);
    setInterestCalcMethods(interestCalculationMethods);
    setRateCategories(rateCategories);
    businessRelationships = translateBusinessRelationShips(businessRelationships);
    setBusinessRelationships(businessRelationships);
    setMileages(milages);
    setResidualValueContracts(residualValueContracts);
    setFundSources(sourceOfFunds);
    setSpecialOffers(specialOffers);
    if (!skipSalesAids) {
      setSalesAids(salesAids);
    }
    setDealers(dealers);
    setFuelServiceProducts(fuelServiceProducts);
  };

  const setExtendedConfiguration = (ec: ExtendedConfiguration) => {
    setValue('fundingLifetimeMin', ec.fundingLifetimeMin);
    setValue('fundingLifetimeMax', ec.fundingLifetimeMax);
    setValue('prepaymentMin', ec.prepaymentMin);
    setValue('totalAmountPayable', ec.totalAmountPayable);
    setValue('commissionInPercent', ec.commissionInPercent);
    setValue('commissionInNumbers', ec.commissionInNumbers);
    setValue('processingFee', ec.processingFee);
    setValue('legalTransactionFee', ec.legalTransactionFee);
    setValue('effectiveInterestRate', ec.effectiveInterestRate);
    setValue('nominalInterestRate', ec.nominalInterestRate);
    setValue('fundingInterestRate', ec.fundingInterestRate);
    setValue('depositInterestRate', ec.depositInterestRate);
    setValue('createdBy', ec.createdBy);
    setValue('editedBy', ec.editedBy);
    setValue('creationDate', ec.creationDate);
    setValue('editedDate', ec.editedDate);
    setValue('voterDate', ec.voterDate);
    setValue('voterUser', ec.voterUser);
    setValue('euriborRate', ec.euriborRate);
    setSubsidies(ec.subsidies || []);
    setViolations(ec.limitViolations || []);
    setFees(ec.fees);
  };

  const setProductFormValues = (fp: FundingProductView) => {
    setValue('financingTypeId', fp.financingTypeId);
    setValue('mileageId', fp.mileageId);
    setValue('duration', fp.duration);
    setValue('businessRelationshipId', fp.businessRelationshipId);
    setValue('downPayment', fp.downpayment);
    setValue('downPaymentPercentage', fp.downpaymentPercentage);
    setValue('deposit', fp.deposit);
    setValue('depositPercentage', fp.depositPercentage);
    setValue('purchaseContractDate', fp.purchaseContractDate);
    // eslint-disable-next-line
    setValue('interestRateCalculationMethodId', fp.interestRateCalculationMethodId);
    setValue('sourceOfFoundsId', fp.sourceOfFoundsId ?? undefined);
    setValue('residualValueId', fp.residualValueContractId ?? undefined);
    setValue('rateCategoryId', fp.rateCategoryId ?? undefined);
    setValue('referencesCalculationId', fp.referencesCalculationId);
    setValue('referencesDealId', fp.referencesDealId);
    setValue('accountOwner', fp.accountOwner);
    setValue('isDirectInkasso', fp.isDirectInkasso);

    if (fp.calculationNumber) {
      setCalcNum(fp.calculationNumber);
    }
    setShowSourceOfFunds(fp.hasMoneyLaunderingRelevantPayments);
    setIsAccountOwnerRequired(fp.accountOwnerRequired);
    setIsDownPaymentReadonly(fp.isDownPaymentReadonly);
    setIsDepositReadonly(fp.isDepositReadonly);
    setHasResidualValue(fp.financingType.hasResidualValue);
    setHasClosingBalance(fp.financingType.hasClosingBalance);
    setHasDeposit(fp.financingType.hasDeposit);
    setIsFinancingLeasing(fp.financingType.isFinancingLeasing);
    setIsResidualValueReadonly(fp.isResidualValueReadonly);
    setIsResidualValueConversionOptionsReadonly(fp.isResidualValueConversionOptionsReadonly);
    setIsLeasing(fp.isLeasing);
    setIsCredit(fp.isCredit);
    setVatFree(fp.financingType.vatFree);
    setIsFuelFinancingType(fp.financingTypeId === fuelFinancingTypeId);
    setIsDurationReadonly(fp.isDurationReadonly);
    setIsMileageReadonly(fp.isMileageReadonly);
    setDisplayGross(fp.displayGross);
  };

  const setCalculatedProduct = (cp: CalculatedProduct) => {
    setValue('residualValuePercentage', cp.residualValuePercentage);
    setValue('residualValueVehicleAbsolute', 0);
    setValue('residualValueVehicleAbsolute', cp.residualValueVehicleAbsolute);
    setValue('ownResources', cp.ownResources);
    setValue('residualValue', cp.residualValueAbsolute ?? 0);
    setValue('rateIncludingVAT', cp.regularInstalmentGross ?? 0);
    setValue('rateExcludingVAT', cp.regularInstalmentNet ?? 0);
    setValue('VAT', cp.regularInstalmentVat ?? 0);
    setValue('hasServices', cp.hasServices ?? false);
    setValue('regularInstalmentTotalGross', cp.regularInstalmentTotalGross);
    setValue('regularInstalmentTotalNet', cp.regularInstalmentTotalNet);
    setValue('regularInstalmentTotalVat', cp.regularInstalmentTotalVat);
    setValue('finalRateFinancingLeasing', cp.finalRateFinancingLeasing ?? 0);
    setDetailedInformation(cp);
  };

  const setDetailedInformation = (cp: CalculatedProduct) => {
    /* Schlussrate inkl. Conversion */
    setValue(
      'finalInstalmentIncludingConversion',
      cp.residualValueProposedWithConversionOptions ?? 0
    );

    /* Offener Restsaldo */
    setValue('outstandingBalance', cp.closingBalance ?? 0);

    /* Summe Eigenmittel */
    setValue('equity', cp.ownResources ?? 0);

    /* Restwert laut Tabelle ==> tableResidualValue <== */
    setValue('tableResidualValue', cp.residualValueProposed ?? 0);

    /* Restwert aus Conversion Options ==> residualValueConversionOptions <== */
    setValue('residualValueConversionOptions', cp.residualValueConversionOptions ?? 0);

    /* Restwertst�tzuung */
    setValue('residualValueSupport', cp.residualValueSubsidyFixum ?? 0);
  };

  const setObjectConfiguration = (oc: any) => {
    setValue('objectConfig', oc);
  };

  const setServiceOptions = (serviceResponse: ApiconServiceBundle) => {
    setServiceProducts(
      serviceResponse.apiconServiceProduct!.serviceProducts!.map((x) => ({
        label: x.productDesignation ?? '',
        value: x.productId ?? '',
      }))
    );
    setProductIndusties(
      serviceResponse.apiconServiceProduct!.productIndusties!.map((x) => ({
        label: x.designation ?? '',
        value: x.productIndustryId ?? '',
      }))
    );
    setProductUsages(
      serviceResponse.apiconServiceProduct!.productUsages!.map((x) => ({
        label: x.designation ?? '',
        value: x.productUsageId ?? '',
      }))
    );
    setCampaigns(
      serviceResponse.apiconServiceProduct!.campaigns!.map((x) => ({
        label: x.designation ?? '',
        value: x.campaignId ?? '',
      }))
    );
    setProductOptions(serviceResponse.apiconServiceProduct!.productOptions!);
    setTechnicalOptions(serviceResponse.apiconServiceProduct!.technicalOptions!);
  };

  const setService = (serviceResponse: ApiconServiceBundle) => {
    setValue('service.cacheGuid', serviceResponse.cacheGuid);
    setValue('service.serviceVariantId', serviceResponse.serviceVariantId);
    setValue('service.duration', serviceResponse.duration);
    setValue('service.mileageId', serviceResponse.mileageId);
    setValue('service.serviceCharge', serviceResponse.serviceCharge);
    setValue('service.serviceChargeNet', serviceResponse.serviceChargeNet);
    setValue('service.baumuster', serviceResponse.baumuster);
    setValue('service.nstCode', serviceResponse.nstCode);
    setValue('service.showModelPrototypeData', serviceResponse.showModelPrototypeData);
    setValue('service.requiresModelPrototypeData', serviceResponse.requiresModelPrototypeData);
    setValue('service.baumusterNstCodeReadOnly', serviceResponse.baumusterNstCodeReadOnly);
    setValue(
      'service.apiconServiceProduct.selectedServiceProductId',
      serviceResponse.apiconServiceProduct!.selectedServiceProductId
    );
    setValue(
      'service.apiconServiceProduct.selectedProductIndustryId',
      serviceResponse.apiconServiceProduct!.selectedProductIndustryId
    );
    setValue(
      'service.apiconServiceProduct.selectedProductUsageId',
      serviceResponse.apiconServiceProduct!.selectedProductUsageId
    );
    setValue(
      'service.apiconServiceProduct.selectedCampaignId',
      serviceResponse.apiconServiceProduct!.selectedCampaignId
    );
    setValue(
      'service.apiconServiceProduct.selectedProductOptions',
      serviceResponse
        .apiconServiceProduct!.productOptions!.filter((x) => x.isSelected)
        .map((x) => x.id ?? '') ?? []
    );
    setValue(
      'service.apiconServiceProduct.selectedTechnicalOptions',
      serviceResponse
        .apiconServiceProduct!.technicalOptions!.filter((x) => x.isSelected)
        .map((x) => x.id ?? '') ?? []
    );
    setValue(
      'service.apiconPriceForm.priceFormState',
      serviceResponse.apiconPriceForm!.priceFormState
    );
    setValue('service.apiconPriceForm.priceNet', 0);
    setValue('service.apiconPriceForm.priceNet', serviceResponse.apiconPriceForm!.priceNet);
    setValue('service.apiconPriceForm.priceGross', serviceResponse.apiconPriceForm!.priceGross);
    setValue('service.apiconPriceForm.vatAsValue', 0);
    setValue('service.apiconPriceForm.vatAsValue', serviceResponse.apiconPriceForm!.vatAsValue);
    setValue('service.apiconPriceForm.totalPrice', serviceResponse.apiconPriceForm!.totalPrice);
    setValue(
      'service.apiconPriceForm.configurationId',
      serviceResponse.apiconPriceForm!.configurationID
    );
    setValue(
      'service.apiconPriceForm.specialCaseMandatory',
      serviceResponse.apiconPriceForm!.specialCaseMandatory
    );
    setValue(
      'service.apiconSpecialCase.priceFormState',
      serviceResponse.apiconSpecialCasePriceForm!.priceFormState ??
        ApiconSpecialCasePriceFormState.Initial
    );
    setValue(
      'service.apiconSpecialCase.priceFormUiState',
      serviceResponse.apiconSpecialCasePriceForm!.priceFormUiState ?? ApiconPriceFormUiState.Initial
    );
    setValue('service.apiconSpecialCase.priceNet', 0);
    setValue(
      'service.apiconSpecialCase.priceNet',
      serviceResponse.apiconSpecialCasePriceForm!.priceNet
    );
    setValue(
      'service.apiconSpecialCase.priceGross',
      serviceResponse.apiconSpecialCasePriceForm!.priceGross
    );
    setValue('service.apiconSpecialCase.vatAsValue', 0);
    setValue(
      'service.apiconSpecialCase.vatAsValue',
      serviceResponse.apiconSpecialCasePriceForm!.vatAsValue
    );
    setValue(
      'service.apiconSpecialCase.comment',
      serviceResponse.apiconSpecialCasePriceForm!.comment
    );
    setValue('service.apiconSpecialCase.phone', serviceResponse.apiconSpecialCasePriceForm!.phone);
    setValue('service.apiconSpecialCase.email', serviceResponse.apiconSpecialCasePriceForm!.eMail);
    setValue(
      'service.apiconSpecialCase.answer',
      serviceResponse.apiconSpecialCasePriceForm!.answer
    );
    setValue(
      'service.apiconSpecialCase.offerId',
      serviceResponse.apiconSpecialCasePriceForm!.offerID
    );
    setValue(
      'service.apiconSpecialCase.specialCaseId',
      serviceResponse.apiconSpecialCasePriceForm!.specialCaseID
    );
    setValue(
      'service.apiconProposalForm.proposalCreationDate',
      serviceResponse.apiconProposalForm!.proposalCreationDate
    );
    setValue(
      'service.apiconProposalForm.proposalValidUntil',
      serviceResponse.apiconProposalForm!.proposalValidUntil
    );
    setValue(
      'service.apiconProposalForm.proposalId',
      serviceResponse.apiconProposalForm!.proposalId
    );
    setValue(
      'service.requestSourceApiconServiceBundleResetCalculation',
      serviceResponse.isServiceVariantsResetRequired
    );
    setIsServiceReadonly(serviceResponse.isReadOnly);
    setIsServiceDurationReadonly(serviceResponse.isDurationReadonly);
    setIsServiceMileageReadonly(serviceResponse.isMileageReadonly);
    setIsServiceVariantsResetRequired(serviceResponse.isServiceVariantsResetRequired);
  };

  const isObjectConfigEmpty = (oc: ObjectConfigurationResponse) => {
    return (
      !oc.listPrice &&
      !oc.coFinancedExtras &&
      !oc.extrasPrice &&
      !oc.purchasePrice &&
      !oc.listPriceIncludingExtras &&
      !oc.totalPrice &&
      !oc.purchasePrice &&
      !oc.calculationBase &&
      !oc.discountPercent &&
      !oc.discountInEuro
    );
  };

  const setServiceConfiguration = (sc: ServiceConfigurationResponse) => {
    setValue('selectedFuelServiceProductId', sc.fuelServiceProductId ?? undefined);
  };

  useEffect(() => {
    RestHttpClient.getSuppliers().then((res) => setSuppliers(res.data));
  }, []);

  const calculateDefault = (fetchCalculation: () => Promise<GetCalculationResponse>) => {
    fetchCalculation()
      .then((data) => {
        const {
          dropdownCollection,
          fundingProductOverView,
          calculatedProduct,
          extendedConfiguration,
          objectConfiguration,
          agentOptions,
          conversionOptions,
          serviceConfiguration,
          serviceResponse,
        } = data;

        const selSalesIndex = dropdownCollection.salesAids.findIndex(
          (s) => s.id === fundingProductOverView.salesAidId
        );
        if (fundingProductOverView.salesAidId && selSalesIndex !== -1) {
          setValue('dealSalesAidId', selSalesIndex);
        } else {
          setValue('specialOfferId', fundingProductOverView.salesAidId);
        }
        setDropdownsOptions(dropdownCollection);
        setCalculatedProduct(calculatedProduct);
        setProductFormValues(fundingProductOverView);
        setDetailedInformation(calculatedProduct);
        setExtendedConfiguration(extendedConfiguration);
        if (!isObjectConfigEmpty(objectConfiguration)) {
          setObjectConfiguration(objectConfiguration);
        } else if (defaultValues && defaultValues.objectConfig) {
          setObjectConfiguration(defaultValues.objectConfig);
        }
        setIsDirectInkassoVisible(data.fundingProductOverView.isDirectInkassoAllowed);

        setIsVote(data.submissionStage === 1 && data.hasPermissionsToVote);

        setInitiallyReconsidered(Boolean(fundingProductOverView.referencesDealId));

        setServiceConfiguration(serviceConfiguration);
        setServiceOptions(serviceResponse);
        setService(serviceResponse);
        setValue('service.serviceVariantId', serviceResponse.serviceVariantId);
        if (calculatedProduct.residualValuesOverwritten) {
          setResidualValuePercentageOld(calculatedProduct.residualValuePercentage);
          setResidualValueVehicleAbsoluteOld(calculatedProduct.residualValueVehicleAbsolute);
          setResidualValueConversionOptionsOld(calculatedProduct.residualValueConversionOptions);
          setCalculatedProduct(calculatedProduct);
        }
        if (agentOptions && agentOptions.length > 0) {
          setValue('agentOptions', agentOptions);
        }
        if (conversionOptions && conversionOptions.length > 0) {
          setValue('conversionOptions', conversionOptions);
        }
        trigger(['residualValuePercentage', 'residualValueVehicleAbsolute']);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isDrawerOpened && !editObject && fetchDefault) {
      setTabValue(calculationTabIndex);
      resetOldValues();
      setLoading(true);
      reset(defaultValues);

      calculateDefault(fetchDefault);
    } else if (editObject) {
      recalculate(editObject);
      reset(editObject);
    }
    if (isDrawerOpened) {
      setIntermFiles([]);
    }
    // eslint-disable-next-line
  }, [isDrawerOpened]);

  const fetchCalculation = async (
    fundingType: number,
    duration: number,
    mileageId: number,
    businessRelationshipId: number,
    interestCalculationMethodId: number | undefined,
    tariffGroup: number | undefined,
    downPayment: number | undefined,
    downPaymentPercent: number | undefined,
    deposit: number | undefined,
    depositPercent: number | undefined,
    residualValueId: number | undefined,
    sourceOfFoundsId: number | undefined,
    accountOwner: string | undefined,
    residualValueVehicleAbsolute: number | undefined,
    residualValueConversionOptions: number | undefined,
    residualValuePercentage: number | undefined,
    specialOfferId: number | undefined,
    purchaseContractDate: Date | undefined,
    dealSalesAidIndex: number | undefined,
    objectConfiguration_ListPrice: number,
    objectConfiguration_ExtrasPrice: number,
    objectConfiguration_ListPriceIncludingExtras: number,
    objectConfiguration_TotalPrice: number,
    objectConfiguration_CoFinancedExtras: number,
    objectConfiguration_PurchasePrice: number,
    objectConfiguration_CalculationBase: number,
    objectConfiguration_DiscountPercent: number,
    objectConfiguration_DiscountInEuro: number,
    objectConfiguration_RegistrationDate: Date | undefined,
    objectConfiguration_InitialMileage: number | undefined,
    objectConfiguration_CommissionNumber: string,
    agentOptions: AgentOptionValues[],
    conversionOptions: ConversionOptionValues[],
    referencesCalculationId: number | undefined,
    service: ServiceValues,
    serviceVariantId: number | undefined,
    cacheGuid: string | undefined,
    referencesDealId: number | undefined,
    ksvRating: number | undefined,
    newSalesAid?: SalesAidView | SalesAidValues,
    selectedFuelServiceProductId?: number | undefined,
    preserveResidualValues?: boolean
  ) => {
    try {
      setLoading(true);
      setApiErros(undefined);
      setIsCalcValid(true);
      const allAids = [...salesAids, ...dealSalesAids];

      const getSalesAid = () => {
        if (newSalesAid) {
          return newSalesAid;
        }
        return dealSalesAidIndex != null && allAids[dealSalesAidIndex]
          ? allAids[dealSalesAidIndex]
          : undefined;
      };

      const recalcParams = {
        fundingType,
        duration,
        mileageId,
        businessRelationshipId,
        interestCalculationMethodId,
        tariffGroup,
        downPayment,
        downPaymentPercent,
        deposit,
        depositPercent,
        residualValueId,
        sourceOfFoundsId,
        accountOwner,
        residualValueVehicleAbsolute,
        residualValueConversionOptions,
        residualValuePercentage,
        specialOfferId,
        purchaseContractDate,
        objectConfiguration_ListPrice,
        objectConfiguration_ExtrasPrice,
        objectConfiguration_ListPriceIncludingExtras,
        objectConfiguration_TotalPrice,
        objectConfiguration_CoFinancedExtras,
        objectConfiguration_PurchasePrice,
        objectConfiguration_CalculationBase,
        objectConfiguration_DiscountPercent,
        objectConfiguration_DiscountInEuro,
        objectConfiguration_RegistrationDate,
        objectConfiguration_InitialMileage,
        objectConfiguration_CommissionNumber,
        salesAid: getSalesAid(),
        agentOptions,
        conversionOptions,
        selectedFuelServiceProductId,
        referencesCalculationId,
        referencesDealId,
        service,
        serviceVariantId,
        cacheGuid,
        ksvRating,
      };

      const data = await fetchCalculate(recalcParams);

      const {
        dropdownCollection,
        fundingProductOverView,
        calculatedProduct,
        extendedConfiguration,
        serviceConfiguration,
        serviceResponse,
      } = data;

      const selSalesAid = dropdownCollection.salesAids.findIndex(
        (s) => s.id === fundingProductOverView.salesAidId
      );
      if (fundingProductOverView.salesAidId && selSalesAid !== -1) {
        setValue('dealSalesAidId', selSalesAid);
      } else {
        setValue('specialOfferId', fundingProductOverView.salesAidId);
      }

      setDropdownsOptions(dropdownCollection, true);

      if (calculatedProduct.residualValuesOverwritten) {
        setResidualValuePercentageOld(calculatedProduct.residualValuePercentage);
        setResidualValueVehicleAbsoluteOld(calculatedProduct.residualValueVehicleAbsolute);
        setResidualValueConversionOptionsOld(calculatedProduct.residualValueConversionOptions);
        setCalculatedProduct(calculatedProduct);
      } else if (
        preserveResidualValues &&
        ((residualValuePercentageOld &&
          residualValuePercentageOld !== calculatedProduct.residualValuePercentage) ||
          (residualValueConversionOptionsOld &&
            residualValueConversionOptionsOld !==
              calculatedProduct.residualValueConversionOptions) ||
          (residualValueVehicleAbsoluteOld &&
            residualValueVehicleAbsoluteOld !== calculatedProduct.residualValueVehicleAbsolute))
      ) {
        const handleNeg = () => {
          setCalculatedProduct(calculatedProduct);
        };

        const handlePos = () => {
          setCalculatedProduct(
            new CalculatedProduct({
              ...calculatedProduct,
              residualValuePercentage: residualValuePercentageOld ?? undefined,
              residualValueVehicleAbsolute: residualValueVehicleAbsoluteOld ?? undefined,
              residualValueConversionOptions: residualValueConversionOptionsOld ?? 0,
            })
          );

          resetOldValues();

          fetchCalculation(
            fundingType,
            duration,
            mileageId,
            businessRelationshipId,
            interestCalculationMethodId,
            tariffGroup,
            downPayment,
            downPaymentPercent,
            deposit,
            depositPercent,
            residualValueId,
            sourceOfFoundsId,
            accountOwner,
            residualValueVehicleAbsoluteOld ?? undefined,
            residualValueConversionOptionsOld,
            residualValuePercentageOld,
            specialOfferId,
            purchaseContractDate,
            dealSalesAidIndex,
            objectConfiguration_ListPrice,
            objectConfiguration_ExtrasPrice,
            objectConfiguration_ListPriceIncludingExtras,
            objectConfiguration_TotalPrice,
            objectConfiguration_CoFinancedExtras,
            objectConfiguration_PurchasePrice,
            objectConfiguration_CalculationBase,
            objectConfiguration_DiscountPercent,
            objectConfiguration_DiscountInEuro,
            objectConfiguration_RegistrationDate,
            objectConfiguration_InitialMileage,
            objectConfiguration_CommissionNumber,
            agentOptions,
            conversionOptions,
            referencesCalculationId,
            service,
            serviceVariantId,
            cacheGuid,
            referencesDealId,
            ksvRating,
            newSalesAid,
            selectedFuelServiceProductId
          );
        };

        confirmHandler.confirm(
          t('deals.new.financesConfiguration.calculationDrawer.confirmOverrideDialogTitle'),
          handlePos,
          handleNeg,
          t('deals.new.financesConfiguration.calculationDrawer.confirmOverrideDialogText'),
          t('deals.new.financesConfiguration.calculationDrawer.confirmOverrideConfirmButton'),
          t('deals.new.financesConfiguration.calculationDrawer.confirmOverrideDeclineButton')
        );
      } else {
        setCalculatedProduct(calculatedProduct);
      }
      setProductFormValues(fundingProductOverView);
      setExtendedConfiguration(extendedConfiguration);
      setServiceConfiguration(serviceConfiguration);
      setService(serviceResponse);
      trigger(['residualValuePercentage', 'residualValueVehicleAbsolute', 'sourceOfFoundsId']);

      if (serviceResponse.reversedChangesIfSpecialCaseRequesting)
        pushNotificationHandler.publish(
          t(
            'deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.reversedChangesIfSpecialCaseRequesting'
          ),
          'warning'
        );

      if (serviceResponse.isServiceVariantsResetRequired)
        pushNotificationHandler.publish(
          t(
            'deals.new.financesConfiguration.calculationDrawer.options.warningInvalidServiceVariants'
          ),
          'warning'
        );

      setIsCalcValid(data.isValid);
    } catch (err) {
      setApiErros(err);
      renderValidationErrors(err, setError);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchServiceVariants = (calculationId: number | undefined) => {
    if (calculationId && calculationId > 0) {
      setServiceVariants([]);
      const { apiconServiceProduct, serviceVariantId } = getValues().service;
      setServiceVariantsLoading(true);
      var selectedServiceVariantId = serviceVariantId ?? undefined;
      var selectedProductId = apiconServiceProduct?.selectedServiceProductId ?? undefined;
      if (isServiceVariantsResetRequired) {
        selectedServiceVariantId = undefined;
        selectedProductId = undefined;
      }
      RestHttpClient.getServiceVariants(
        calculationId,
        selectedServiceVariantId,
        selectedProductId,
        isServiceVariantsResetRequired
      )
        .then((res) => {
          setServiceVariants(
            res.data.serviceVariants.map((serviceVariant) => ({
              id: serviceVariant.id ?? '',
              variantNumber: serviceVariant.variantNumber,
              serviceProduct: serviceVariant.serviceProduct ?? '-',
              productIndustry: serviceVariant.productIndustry ?? '-',
              productUsage: serviceVariant.productUsage ?? '-',
              productOptions: serviceVariant.productOptions ?? '-',
              rate: serviceVariant.rate.toFixed(2).toString(),
              comment: serviceVariant.comment ?? '-',
              phone: serviceVariant.phone ?? '-',
              email: serviceVariant.email ?? '-',
              answer: serviceVariant.answer ?? '-',
            }))
          );
        })
        .catch((err) => {
          renderValidationErrors(err, setError);
        })
        .finally(() => {
          setServiceVariantsLoading(false);
        });
    } else setServiceVariants([]);
  };

  const recalculate = (
    overrideValues = {},
    newSalesAid?: SalesAidView | SalesAidValues,
    preserveResidualValues?: boolean
  ) => {
    const allValues = Object.assign({ ...getValues() }, overrideValues);

    allValues.objectConfig = Object.assign(
      {},
      getValues().objectConfig,
      // @ts-ignore
      overrideValues.objectConfig
    );
    const {
      financingTypeId,
      duration,
      mileageId,
      businessRelationshipId,
      residualValueId,
      interestRateCalculationMethodId,
      rateCategoryId,
      downPayment,
      downPaymentPercentage,
      deposit,
      depositPercentage,
      sourceOfFoundsId,
      accountOwner,
      residualValueConversionOptions,
      residualValueVehicleAbsolute,
      residualValuePercentage,
      specialOfferId,
      purchaseContractDate,
      dealSalesAidId,
      agentOptions,
      conversionOptions,
      selectedFuelServiceProductId,
      referencesCalculationId,
      referencesDealId,
      service,
      credibilityCheck,
    } = allValues;
    if (
      financingTypeId != null &&
      duration != null &&
      mileageId != null &&
      businessRelationshipId != null
    ) {
      const serviceValues = service as ServiceValues;
      fetchCalculation(
        financingTypeId,
        duration,
        mileageId,
        businessRelationshipId,
        interestRateCalculationMethodId,
        rateCategoryId,
        downPayment ?? undefined,
        downPaymentPercentage ?? undefined,
        deposit ?? undefined,
        depositPercentage ?? undefined,
        residualValueId,
        sourceOfFoundsId,
        accountOwner ?? undefined,
        residualValueVehicleAbsolute ?? undefined,
        residualValueConversionOptions ?? undefined,
        residualValuePercentage ?? undefined,
        specialOfferId ?? undefined,
        purchaseContractDate ?? undefined,
        dealSalesAidId ?? undefined,
        allValues.objectConfig.listPrice,
        allValues.objectConfig.extrasPrice,
        allValues.objectConfig.listPriceIncludingExtras,
        allValues.objectConfig.totalPrice,
        allValues.objectConfig.coFinancedExtras,
        allValues.objectConfig.purchasePrice,
        allValues.objectConfig.calculationBase,
        allValues.objectConfig.discountPercent,
        allValues.objectConfig.discountInEuro,
        allValues.objectConfig.registrationDate,
        allValues.objectConfig.initialMileage ?? undefined,
        allValues.objectConfig.commissionNumber ?? '',
        agentOptions ? agentOptions : [],
        conversionOptions ? conversionOptions : [],
        referencesCalculationId ?? undefined,
        serviceValues,
        allValues.service.serviceVariantId ?? undefined,
        allValues.service.cacheGuid ?? undefined,
        referencesDealId ?? undefined,
        credibilityCheck?.ksvRating ?? undefined,
        newSalesAid,
        selectedFuelServiceProductId || undefined,
        preserveResidualValues
      );
    }
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue === servicesTabIndex) loadServiceData();
  };

  const loadServiceData = () => {
    const values = getValues();
    if (financingTypeId !== 53 && financingTypeId !== 31) return;

    if (
      values.service.requiresModelPrototypeData &&
      !values.service.baumuster &&
      !values.service.nstCode
    )
      return; // legacy: Skip request, since we need don't yet have baumuster values on initial request

    setLoading(true);
    RestHttpClient.getServices(
      modelId ?? 0,
      20,
      distributionChannel ?? 0,
      values.service.cacheGuid ?? undefined,
      dealId ?? -1,
      calculationId,
      isServiceVariantsResetRequired ? undefined : values.service.serviceVariantId ?? undefined,
      undefined,
      isServiceVariantsResetRequired
        ? ''
        : values.service.apiconServiceProduct?.selectedServiceProductId ?? '',
      values.service.mileageId ?? -1,
      values.service.duration ?? -1,
      isServiceVariantsResetRequired,
      values.service.serviceChargeNet,
      values.service.apiconPriceForm?.configurationId ?? '',
      values.service.apiconServiceProduct?.selectedProductOptions,
      values.service.apiconServiceProduct?.selectedProductIndustryId ?? '',
      values.service.apiconServiceProduct?.selectedProductUsageId ?? '',
      values.service.apiconServiceProduct?.selectedTechnicalOptions,
      values.service.apiconServiceProduct?.selectedCampaignId ?? '',
      isServiceVariantsResetRequired
        ? undefined
        : values.service.apiconPriceForm?.priceNet ?? undefined,
      values.service.baumuster ?? '',
      values.service.nstCode ?? '',
      values.financingTypeId,
      isServiceVariantsResetRequired
        ? undefined
        : values.service.apiconPriceForm?.totalPrice ?? undefined,
      values.service.apiconPriceForm?.priceFormState ?? ApiconSpecialCasePriceFormState.Initial,
      values.service.apiconSpecialCase?.comment ?? '',
      values.service.apiconSpecialCase?.phone ?? '',
      values.service.apiconSpecialCase?.email ?? '',
      values.service.apiconSpecialCase?.offerId ?? '',
      values.service.apiconSpecialCase?.specialCaseId ?? '',
      false,
      true,
      values.service.apiconPriceForm?.specialCaseMandatory ?? false,
      false
    )
      .then(({ data: serviceResponse }) => {
        setServiceOptions(serviceResponse);
        setService(serviceResponse);
        fetchServiceVariants(calculationId);
      })
      .catch((err) => {
        renderValidationErrors(err, setError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const calculateServicePrice = () => {
    const values = getValues();
    const result = schema.safeParse(values);

    if (!result.success) return;

    if (!values.service.apiconServiceProduct?.selectedServiceProductId) {
      setError('service.apiconServiceProduct.selectedServiceProductId', { type: 'required' });
      return;
    }

    setLoading(true);

    const req = new CalculateServicePriceRequest({
      modelId: modelId ?? 0,
      vat: 20,
      distributionChannel: distributionChannel ?? 0,
      cacheGuid: values.service.cacheGuid ?? undefined,
      dealId: dealId ?? -1,
      calculationId: calculationId,
      serviceVariantId: undefined,
      variantNumber: undefined,
      serviceProductId: values.service.apiconServiceProduct?.selectedServiceProductId ?? undefined,
      mileageId: values.service.mileageId ?? -1,
      duration: values.service.duration ?? -1,
      isServiceVariantsResetRequest: isServiceVariantsResetRequired,
      serviceChargeNet: values.service.serviceChargeNet,
      configurationID: values.service.apiconPriceForm?.configurationId ?? '',
      productOptionIds: values.service.apiconServiceProduct?.selectedProductOptions,
      productIndustryId:
        values.service.apiconServiceProduct?.selectedProductIndustryId ?? undefined,
      productUsageId: values.service.apiconServiceProduct?.selectedProductUsageId ?? undefined,
      technicalOptionIds: values.service.apiconServiceProduct?.selectedTechnicalOptions,
      campaignId: values.service.apiconServiceProduct?.selectedCampaignId ?? undefined,
      priceNet: values.service.apiconPriceForm?.priceNet ?? undefined,
      baumuster: values.service.baumuster ?? '',
      nstCode: values.service.nstCode ?? '',
      fundingProductId: values.financingTypeId,
      totalPrice: values.service.apiconPriceForm?.totalPrice ?? undefined,
      priceFormState:
        values.service.apiconPriceForm?.priceFormState ?? ApiconSpecialCasePriceFormState.Initial,
      specialCaseRequestBundle: new ApiconSpecialCaseRequestBundle({
        comment: values.service.apiconSpecialCase?.comment ?? '',
        phone: values.service.apiconSpecialCase?.phone ?? '',
        eMail: values.service.apiconSpecialCase?.email ?? '',
        offerID: values.service.apiconSpecialCase?.offerId ?? '',
        specialCaseID: values.service.apiconSpecialCase?.specialCaseId ?? '',
      }),
      preCheckServiceProductSelectionsFromRequest: true,
      preCheckServiceProductSelectionsFromApicon: false,
      isPriceFormSpecialCaseMandatory:
        values.service.apiconPriceForm?.specialCaseMandatory ?? false,
      isServiceVariantSaveRequest: false,
    });
    RestHttpClient.calculateServicePrice(req)
      .then(({ data: serviceResponse }) => {
        setServiceOptions(serviceResponse);
        setService(serviceResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSaveServiceVariant = (event: FormEvent, isServiceVariantSaveRequest: boolean) => {
    if (calculationId === undefined)
      confirmHandler.confirm(
        t('deals.new.financesConfiguration.calculationDrawer.options.saveVariantConfirm'),
        () => onFormSubmit(event, isServiceVariantSaveRequest)
      );
    else onFormSubmit(event, isServiceVariantSaveRequest);
  };

  const saveServiceVariant = (values: FinancesDrawerFormValues, calculationId: number) => {
    if (!schema.safeParse(values).success) return;
    if (
      !(values.service.apiconPriceForm?.specialCaseMandatory ?? false) &&
      (values.service.apiconPriceForm?.priceNet ?? 0) <= 0
    ) {
      pushNotificationHandler.publish(
        t(
          'deals.new.financesConfiguration.calculationDrawer.options.pleaseRequestPriceBeforeSaveVariant'
        ),
        'error'
      );
      return;
    }
    setLoading(true);

    const req = new SaveServiceVariantRequest({
      calculationId: calculationId,
      dealId: dealId ?? -1,
      serviceVariantId: undefined,
      variantNumber: undefined,
      cacheGuid: values.service.cacheGuid ?? undefined,
      mileageId: values.service.mileageId ?? -1,
      serviceProductId: values.service.apiconServiceProduct?.selectedServiceProductId ?? '',
      productIndustryId: values.service.apiconServiceProduct?.selectedProductIndustryId ?? '',
      productUsageId: values.service.apiconServiceProduct?.selectedProductUsageId ?? '',
      campaignId: values.service.apiconServiceProduct?.selectedCampaignId ?? '',
      productOptionIds: values.service.apiconServiceProduct?.selectedProductOptions ?? [],
      technicalOptionIds: values.service.apiconServiceProduct?.selectedTechnicalOptions ?? [],
      priceNet: values.service.apiconPriceForm?.priceNet ?? undefined,
      totalPrice: values.service.apiconPriceForm?.totalPrice ?? undefined,
      priceFormState:
        values.service.apiconPriceForm?.priceFormState ?? ApiconSpecialCasePriceFormState.Initial,
      configurationID: values.service.apiconPriceForm?.configurationId ?? '',
      isPriceFormSpecialCaseMandatory:
        values.service.apiconPriceForm?.specialCaseMandatory ?? false,
      baumuster: values.service.baumuster ?? undefined,
      nstCode: values.service.nstCode ?? undefined,
    });
    RestHttpClient.saveServiceVariant(req).then(({ data }) => {
      setValue('service.serviceVariantId', data.serviceVariantId);
      fetchServiceVariants(calculationId); // TODO optimieren
      if (fetchDefault) calculateDefault(() => fetchDefault(calculationId));
      pushNotificationHandler.publish(
        t('deals.new.financesConfiguration.calculationDrawer.options.savedServiceVariant', {
          variantNumber: data.variantNumber,
        }),
        'success'
      );
    });
  };

  const onCreateProposal = () => {
    const values = getValues();
    if (!schema.safeParse(values).success) return;

    if (values.service.serviceVariantId == null) {
      renderValidationErrors(
        t('deals.new.financesConfiguration.calculationDrawer.options.pleaseSelectVariant'),
        setError
      );
      return;
    }

    setLoading(true);
    const serviceValues = getValues().service as ServiceValues;
    const req = new SaveProposalRequest(
      createCommonServiceRequest(serviceValues, dealId, calculationId, financingTypeId)
    );
    RestHttpClient.saveProposal(req)
      .then(({ data: serviceResponse }) => {
        setServiceOptions(serviceResponse);
        setService(serviceResponse);
        handleRelevantChange('saveProposal', null);
      })
      .catch((err) => {
        renderValidationErrors(err, setError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function onRequestSpecialCasePrice(specialCaseValues: SpecialCaseValues): Promise<void> {
    const values = getValues();
    setValue('service.apiconSpecialCase.comment', specialCaseValues.comment);
    setValue('service.apiconSpecialCase.phone', specialCaseValues.phone);
    setValue('service.apiconSpecialCase.email', specialCaseValues.email);
    if (!schema.safeParse(values).success) return;

    setLoading(true);
    try {
      const serviceValues = getValues().service as ServiceValues;
      const req = new CreateSpecialCasePriceRequest(
        createCommonServiceRequest(serviceValues, dealId, calculationId, financingTypeId)
      );
      await RestHttpClient.createSpecialCasePrice(req)
        .then(({ data: serviceResponse }) => {
          setServiceOptions(serviceResponse);
          setService(serviceResponse);
          fetchServiceVariants(calculationId);
          pushNotificationHandler.publish(
            t(
              'deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.inProgress'
            ),
            'success'
          );
        })
        .catch((err) => {
          renderValidationErrors(err, setError);
        });
    } finally {
      setLoading(false);
    }
  }

  const onCompleteSpecialCasePrice = () => {
    const values = getValues();
    if (!schema.safeParse(values).success) return;

    setLoading(true);
    const serviceValues = getValues().service as ServiceValues;
    const req = new CompleteSpecialCasePriceRequest(
      createCommonServiceRequest(serviceValues, dealId, calculationId, financingTypeId)
    );
    RestHttpClient.completeSpecialCasePrice(req)
      .then(({ data: serviceResponse }) => {
        setServiceOptions(serviceResponse);
        setService(serviceResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFormSubmit = (event: FormEvent, isServiceVariantSaveRequest: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    setTabErrors([]);
    const handleSubmitCallback = () => {
      handleSubmit(
        (values) => {
          if (showSourceOfFunds && !values.sourceOfFoundsId) {
            setError('sourceOfFoundsId', { type: 'required' });
            return Promise.reject();
          }
          if (isFuelFinancingType && !values.selectedFuelServiceProductId) {
            setError('selectedFuelServiceProductId', { type: 'required' });
            return Promise.reject();
          }

          const allAids: SalesAidView[] = [
            ...salesAids,
            ...dealSalesAids.map(mapSalesAidValuesToView),
          ];
          const salesAid =
            values.dealSalesAidId != null && allAids[values.dealSalesAidId]
              ? allAids[values.dealSalesAidId]
              : undefined;
          const sendValues: FinancesCalculationContextValues = {
            ...values,
            isLeasing: isLeasing,
            isCredit: isCredit,
            financingType:
              financingTypes.find((ft) => ft.value === values.financingTypeId)?.label ?? '-',
            residualValueLabel:
              residualValueContracts.find((icm) => icm.value === values.residualValueId)?.label ??
              '-',
            rateCategory:
              rateCategories.find((rc) => rc.value === values.rateCategoryId)?.label ?? '-',
            mileage: mileages.find((m) => m.value === values.mileageId)?.label ?? '-',
            id: editObject?.id ?? undefined,
            salesAid: salesAid,
            specialOfferId: salesAid ? undefined : values.specialOfferId,
            salesAids: allAids,
          };

          sendValues.removeReconsiderationReference = initiallyReconsidered
            ? sendValues.removeReconsiderationReference
            : false;

          const submitCallback = () => {
            setLoading(true);
            onSubmit(
              sendValues,
              isServiceVariantSaveRequest,
              technicalOptions,
              intermFiles,
              isReload
            )
              .then((newCalculationId) => {
                if (setCalculationId !== undefined) setCalculationId(newCalculationId);
                if (isServiceVariantSaveRequest && newCalculationId !== undefined)
                  saveServiceVariant(values, newCalculationId);
                else reset({});
                setIntermFiles([]);
                setIsDrawerOpened(isServiceVariantSaveRequest);
              })
              .finally(() => {
                if (!isServiceVariantSaveRequest) setLoading(false);
              });
          };
          if (
            values.conversionOptions &&
            values.conversionOptions.findIndex((co) => !co.supplierId) !== -1
          ) {
            confirmHandler.confirm(
              t('deals.new.financesConfiguration.calculationDrawer.agentOption.unknownSupplier'),
              submitCallback,
              undefined,
              t('deals.new.financesConfiguration.calculationDrawer.agentOption.unkownMessage')
            );
          } else {
            submitCallback();
          }
        },
        (err) => {
          customErrorHandler<FinancesDrawerFormValues>(err, [
            {
              fields: [
                'specialOfferId',
                'dealSalesAidId',
                'purchaseContractDate',
                'specialOfferId',
                'dealSalesAidId',
                'financingTypeId',
                'duration',
                'mileageId',
                'interestRateCalculationMethodId',
                'residualValueId',
                'rateCategoryId',
                'downPaymentPercentage',
                'downPayment',
                'depositPercentage',
                'deposit',
                'sourceOfFoundsId',
                'businessRelationshipId',
                'accountOwner',
                'residualValuePercentage',
                'residualValueVehicleAbsolute',
                'residualValueConversionOptions',
              ],
              handler: () => {
                setTabErrors([...tabErrors, calculationTabIndex]);
              },
            },
            {
              fields: ['objectConfig'],
              handler: () => {
                setTabErrors([...tabErrors, objectTabIndex]);
              },
            },
            {
              fields: ['selectedFuelServiceProductId'],
              handler: () => {
                setTabErrors([...tabErrors, servicesTabIndex]);
              },
            },
            {
              fields: ['conversionOptions', 'agentOptions'],
              handler: () => {
                setTabErrors([...tabErrors, novaTabIndex]);
              },
            },
          ]);
        }
      )(event);
    };

    if (isServiceVariantsResetRequired) {
      confirmHandler.confirm(
        t('deals.new.financesConfiguration.calculationDrawer.confirmResetServiceVariantsTitle'),
        handleSubmitCallback,
        () => {
          return Promise.reject;
        },
        t('deals.new.financesConfiguration.calculationDrawer.confirmResetServiceVariants')
      );
    } else {
      handleSubmitCallback();
    }
  };

  const handleRelevantChange = (name: string, value: unknown) => {
    console.log(name);
    if (name === 'referencesDealId') {
      const overtakeCustomer = getValues('overtakeCustomer');
      if (Number.isInteger(value)) {
        fetchReconsideredDeal(Number(value), Boolean(overtakeCustomer));
      } else {
        recalculate({});
      }
    } else if (name === 'downPaymentPercentage') {
      recalculate({
        downPaymentPercentage: Number(value),
        downPayment: undefined,
      });
    } else if (name === 'depositPercentage') {
      recalculate({
        depositPercentage: Number(value),
        deposit: undefined,
      });
    } else if (name === 'downPayment') {
      recalculate({
        downPaymentPercentage: undefined,
        downPayment: Number(value),
      });
    } else if (name === 'deposit') {
      recalculate({
        deposit: Number(value),
        depositPercentage: undefined,
      });
    } else if (name === 'financingTypeId') {
      const dur: number = getValues('duration');
      setValue('specialOfferId', null);
      setValue('dealSalesAidId', null);
      setResidualValuePercentageOld(undefined);
      setResidualValueVehicleAbsoluteOld(undefined);
      setResidualValueConversionOptionsOld(undefined);
      recalculate({
        duration: dur >= 120 ? 72 : dur,
        rateCategoryId: undefined,
        residualValueId: undefined,
        interestRateCalculationMethodId: undefined,
        residualValueVehicleAbsolute: undefined,
        residualValueConversionOptions: undefined,
        residualValuePercentage: undefined,
        selectedFuelServiceProductId: undefined,
        specialOfferId: null,
        dealSalesAidId: null,
      });
      if (value === fuelFinancingTypeId) {
        setTabValue(servicesTabIndex);
      }
    } else if (name === 'residualValuePercentage') {
      const residualValueConversionOptions: number | undefined = getValues(
        'residualValueConversionOptions'
      );
      recalculate({
        residualValueVehicleAbsolute: undefined,
        residualValueConversionOptions: residualValueConversionOptions,
        residualValuePercentage: Number(value),
      });
    } else if (name === 'residualValueVehicleAbsolute') {
      const residualValueConversionOptions: number | undefined = getValues(
        'residualValueConversionOptions'
      );
      recalculate({
        residualValueVehicleAbsolute: Number(value),
        residualValueConversionOptions: residualValueConversionOptions,
        residualValuePercentage: undefined,
      });
    } else if (name === 'residualValueConversionOptions') {
      const residualValueVehicleAbsolute: number | undefined | null = getValues(
        'residualValueVehicleAbsolute'
      );
      const residualValuePercentage: number | null | undefined =
        getValues('residualValuePercentage');
      recalculate({
        residualValueVehicleAbsolute: residualValueVehicleAbsolute,
        residualValueConversionOptions: Number(value),
        residualValuePercentage: residualValuePercentage,
      });
    } else if (name === 'residualValueId' || name === 'mileageId' || name === 'duration') {
      recalculate({
        [name]: value,
        residualValueVehicleAbsolute: undefined,
        residualValueConversionOptions: undefined,
        residualValuePercentage: undefined,
      });
    } else if (name === 'specialOfferEnabled') {
      if (!value) {
        setValue('specialOfferId', null);
        setValue('dealSalesAidId', null);
        setValue('purchaseContractDate', null);
        setResidualValuePercentageOld(undefined);
        setResidualValueConversionOptionsOld(undefined);
        setResidualValueVehicleAbsoluteOld(undefined);
        recalculate(
          {
            specialOfferId: null,
            dealSalesAidId: null,
            rateCategoryId: undefined,
            residualValueVehicleAbsolute: undefined,
            residualValueConversionOptions: undefined,
            residualValuePercentage: undefined,
          },
          undefined,
          true
        );
      }
    } else if (name === 'dealSalesAidId') {
      setValue('specialOfferId', null);
      setResidualValuePercentageOld(undefined);
      setResidualValueConversionOptionsOld(undefined);
      setResidualValueVehicleAbsoluteOld(undefined);
      recalculate(
        {
          [name]: value,
          specialOfferId: null,
          rateCategoryId: undefined,
          residualValueVehicleAbsolute: undefined,
          residualValueConversionOptions: undefined,
          residualValuePercentage: undefined,
        },
        undefined,
        true
      );
    } else if (name === 'salesAids') {
      setValue('specialOfferId', null);
      setResidualValuePercentageOld(undefined);
      setResidualValueConversionOptionsOld(undefined);
      setResidualValueVehicleAbsoluteOld(undefined);
      recalculate(
        {
          specialOfferId: null,
          rateCategoryId: undefined,
          residualValueVehicleAbsolute: undefined,
          residualValueConversionOptions: undefined,
          residualValuePercentage: undefined,
        },
        value as SalesAidValues,
        true
      );
    } else if (name === 'specialOfferId') {
      setValue('dealSalesAidId', null);
      setResidualValuePercentageOld(undefined);
      setResidualValueConversionOptionsOld(undefined);
      setResidualValueVehicleAbsoluteOld(undefined);
      recalculate(
        {
          [name]: value,
          dealSalesAidId: null,
          rateCategoryId: undefined,
          residualValueVehicleAbsolute: undefined,
          residualValueConversionOptions: undefined,
          residualValuePercentage: undefined,
        },
        undefined,
        true
      );
    } else if (
      name === 'agentOptions' ||
      name === 'conversionOptions' ||
      name === 'objectConfig.totalPrice' ||
      name === 'objectConfig.extrasPrice' ||
      name === 'objectConfig.listPrice'
    ) {
      const objectConfig = getValues('objectConfig');
      setIsReload(true);
      if (objectConfig) {
        const ao = getValues('agentOptions') || [];
        const co = getValues('conversionOptions') || [];
        const oldCalcBase = getValues('objectConfig.calculationBase') || 0;

        const aoSum = ao.filter((a) => a.price > 0).reduce((total, item) => total + item.price, 0);
        const coSum = co.filter((c) => c.price > 0).reduce((total, item) => total + item.price, 0);
        let novaSum = aoSum + coSum;

        console.log('Novasum vor Umrechnung: ' + novaSum);

        if (!isIncludingVat && displayGross) {
          novaSum = (novaSum / 120) * 100;
        } else if (isIncludingVat && !displayGross) {
          novaSum = novaSum * (1 + 20 / 100);
        }

        console.log('Novasum: ' + novaSum);

        const totalPrice =
          name === 'objectConfig.totalPrice' ? Number(value) : objectConfig.totalPrice;
        if (name === 'objectConfig.totalPrice') {
          setValue('objectConfig.totalPrice', Number(value));
        }
        const calcBase = totalPrice + novaSum;
        setValue('objectConfig.coFinancedExtras', novaSum);
        setValue('objectConfig.purchasePrice', calcBase);
        if (oldCalcBase !== calcBase) {
          setValue('objectConfig.calculationBase', calcBase);
          const objectConfRes = new ObjectConfigurationResponse({
            ...objectConfig,
            coFinancedExtras: novaSum,
            purchasePrice: calcBase,
            calculationBase: calcBase,
            initialMileage: objectConfig.initialMileage ?? undefined,
            commissionNumber: objectConfig.commissionNumber ?? undefined,
          });
          if (name === 'objectConfig.totalPrice') {
            recalculate({
              objectConfig: objectConfRes,
            });
          } else {
            recalculate(
              {
                residualValueVehicleAbsolute: undefined,
                residualValueConversionOptions: undefined,
                residualValuePercentage: undefined,
                objectConfig: objectConfRes,
              },
              undefined,
              true
            );
          }
        }
      }
    } else if (name === 'conversionOptionSupplier') {
      recalculate(
        {
          residualValueVehicleAbsolute: undefined,
          residualValueConversionOptions: undefined,
          residualValuePercentage: undefined,
        },
        undefined,
        true
      );
    } else if (
      name === 'objectConfig.listPriceIncludingExtras' ||
      name === 'objectConfig.initialMileage' ||
      name === 'objectConfig.registrationDate'
    ) {
      recalculate({
        [name]: value,
        residualValueVehicleAbsolute: undefined,
        residualValueConversionOptions: undefined,
        residualValuePercentage: undefined,
      });
    } else if (name === 'purchaseContractDate') {
      recalculate({
        [name]: value,
        rateCategoryId: undefined,
        residualValueVehicleAbsolute: undefined,
        residualValueConversionOptions: undefined,
        residualValuePercentage: undefined,
        dealSalesAidId: undefined,
        specialOfferId: undefined,
      });
    } else if (name === 'rateCategoryId') {
      recalculate({ [name]: value }, undefined, true);
    } else if (name === 'saveProposal') {
      recalculate({});
    } else if (
      name === 'vinAssignments' ||
      name === 'credibilityCheck.ksvRating' ||
      name === 'credibilityCheck.roracUrl'
    ) {
      setIsReload(true);
    } else {
      recalculate({ [name]: value });
    }
  };

  const handleClose = () => {
    reset({});
    setFinancingTypes([]);
    setMileages([]);
    setInterestCalcMethods([]);
    setRateCategories([]);
    setFundSources([]);
    setBusinessRelationships([]);
    setResidualValueContracts([]);
    setHasDeposit(false);
    setHasClosingBalance(false);
    setHasResidualValue(false);
    setIsFinancingLeasing(false);
    setIsDrawerOpened(false);
  };

  function translateInterestCalculationMethods(data: OptionItem[]): OptionItem[] {
    (Object.keys(data) as (keyof typeof OptionItem)[]).forEach((key, index) => {
      data[index].label = i18n.t([
        `deals.new.interestCalculationMethods.${data[index].label}`,
        data[index].label,
      ]);
    });
    return data;
  }

  function translateBusinessRelationShips(data: OptionItem[]): OptionItem[] {
    (Object.keys(data) as (keyof typeof OptionItem)[]).forEach((key, index) => {
      data[index].label = i18n.t([
        `deals.new.businessRelationships.${data[index].label}`,
        data[index].label,
      ]);
    });
    return data;
  }

  function handleOnSalesAidAdd(salesAid: SalesAidValues) {
    onAddSalesAid(salesAid);
    const newIndex = salesAids.length + dealSalesAids.length;
    setValue('specialOfferId', null);
    setValue('dealSalesAidId', newIndex);
    handleRelevantChange('salesAids', salesAid);
  }

  function handleOnSalesAidEdit(salesAid: SalesAidValues, index: number) {
    if (index >= salesAids.length) {
      onEditSalesAid(salesAid, salesAids.length - index);
      setValue('specialOfferId', null);
      setValue('dealSalesAidId', index);
      handleRelevantChange('salesAids', salesAid);
    } else {
      const newAids = [...salesAids];
      newAids[index] = mapSalesAidValuesToView(salesAid);
      setSalesAids(newAids);
      handleRelevantChange('salesAids', salesAid);
    }
  }

  const fetchReconsideredDeal = async (referencesDealId: number, overtakeCustomer: boolean) => {
    try {
      setLoading(true);
      const data = await fetchReconsideredFundingProduct(referencesDealId, overtakeCustomer);

      const {
        dropdownCollection,
        fundingProductOverView,
        calculatedProduct,
        extendedConfiguration,
        serviceConfiguration,
        serviceResponse,
      } = data;

      const selSalesAid = dropdownCollection.salesAids.findIndex(
        (s) => s.id === fundingProductOverView.salesAidId
      );
      if (fundingProductOverView.salesAidId && selSalesAid !== -1) {
        setValue('dealSalesAidId', selSalesAid);
      } else {
        setValue('specialOfferId', fundingProductOverView.salesAidId);
      }

      setDropdownsOptions(dropdownCollection);
      setCalculatedProduct(calculatedProduct);
      setProductFormValues(fundingProductOverView);
      setExtendedConfiguration(extendedConfiguration);
      setServiceConfiguration(serviceConfiguration);
      setService(serviceResponse);
      setValue('overtakenCustomerId', data.overtakenCustomerId);
    } finally {
      setLoading(false);
    }
  };

  const width = { xl: '70vw', lg: '90vw', sm: '100vw' };

  const fuelFinancingTypeId = 67;

  const calculationTabIndex = 0;
  const objectTabIndex = 1;
  const servicesTabIndex = 2;
  const novaTabIndex = 3;

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: { height: '100vh', width: width, position: 'fixed', bottom: 0, right: 0 },
        }}
        anchor="right"
        open={isDrawerOpened}
        onClose={handleClose}
      >
        <Box display="flex" className="new-deal-drawer">
          <Grid container wrap="nowrap" alignItems="stretch" height="100%">
            <Grid item xs height="100%" alignItems="stretch">
              <Tabs
                aria-label="simple tab"
                value={tabValue}
                onChange={handleChange}
                orientation="vertical"
                sx={{
                  backgroundColor: '#F1F1F1',
                  height: '100%',
                  minHeight: '100vh',
                  width: '100%',
                  flex: 1,
                }}
              >
                <Tab
                  label={t('deals.new.financesConfiguration.calculationDrawer.calculation')}
                  sx={{
                    mt: 3,
                    color: tabErrors.includes(calculationTabIndex) ? 'red !important' : undefined,
                  }}
                  {...a11yProps(calculationTabIndex)}
                />
                <Tab
                  label={t('deals.new.financesConfiguration.calculationDrawer.object')}
                  {...a11yProps(objectTabIndex)}
                  sx={{ color: tabErrors.includes(objectTabIndex) ? 'red !important' : undefined }}
                />
                {(financingTypeId === 31 ||
                  financingTypeId === 53 ||
                  financingTypeId === fuelFinancingTypeId) && (
                  <Tab
                    label={t('deals.new.financesConfiguration.calculationDrawer.services.title')}
                    {...a11yProps(servicesTabIndex)}
                    sx={{
                      color: tabErrors.includes(servicesTabIndex) ? 'red !important' : undefined,
                    }}
                  />
                )}
                <Tab
                  label={t('deals.new.financesConfiguration.calculationDrawer.agentOptions')}
                  {...a11yProps(novaTabIndex)}
                  sx={{ color: tabErrors.includes(novaTabIndex) ? 'red !important' : undefined }}
                  disabled={isFuelFinancingType}
                />
              </Tabs>
            </Grid>
            <Grid item xs={10}>
              {loading && <LinearProgress />}
              <Box sx={{ height: 'auto', display: 'flex', flexDirection: 'column' }}>
                <form
                  id="finances-calculation"
                  onSubmit={(event) => onFormSubmit(event, false)}
                  noValidate
                >
                  <TabPanel value={tabValue} index={calculationTabIndex}>
                    <FinancesCalculation
                      dealId={dealId}
                      calculationId={calculationId}
                      calcNum={calcNum}
                      onRelevantChange={handleRelevantChange}
                      sx={{ width: '100%', flex: '1 1 30%', paddingBottom: '200px' }}
                      control={control}
                      financingTypes={financingTypes}
                      mileages={mileages}
                      interestCalcMethods={interestCalcMethods}
                      rateCategories={rateCategories}
                      fundSources={fundSources}
                      businessRelationships={businessRelationships}
                      residualValueContracts={residualValueContracts}
                      isFinancingLeasing={isFinancingLeasing}
                      hasResidualValue={hasResidualValue}
                      hasDeposit={hasDeposit}
                      hasClosingBalance={hasClosingBalance}
                      isDownPaymentReadonly={isDownPaymentReadonly}
                      isResidualValueReadonly={isResidualValueReadonly}
                      isResidualValueConversionOptionsReadonly={
                        isResidualValueConversionOptionsReadonly
                      }
                      isDepositReadonly={isDepositReadonly}
                      isAccountOwnerRequired={isAccountOwnerRequired}
                      showSourceOfFunds={showSourceOfFunds}
                      isDirectInkassoVisible={isDirectInkassoVisible}
                      isIncludingVat={isIncludingVat}
                      vatFree={vatFree}
                      isCredit={isCredit}
                      isLeasing={isLeasing}
                      loading={loading}
                      specialOffers={specialOffers}
                      dealSalesAids={[...salesAids, ...dealSalesAids]}
                      onAddSalesAid={handleOnSalesAidAdd}
                      onEditSalesAid={handleOnSalesAidEdit}
                      subsidies={subsidies}
                      violations={violations}
                      fees={fees}
                      isMileageReadonly={isMileageReadonly}
                      isDurationReadonly={isDurationReadonly}
                      readonly={readonly}
                      fetchSubmittedDeals={fetchSubmittedDeals}
                      setValue={setValue}
                    />
                  </TabPanel>

                  <TabPanel value={tabValue} index={objectTabIndex}>
                    <FinancesObject
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      sx={{ width: '100%', paddingBottom: '200px' }}
                      isIncludingVat={isIncludingVat}
                      vatFree={vatFree}
                      onRelevantChange={handleRelevantChange}
                      loading={loading}
                      readonly={readonly}
                      dealId={dealId}
                      modelId={modelId}
                      dealTypeId={dealTypeId}
                      vehicleCondition={vehicleCondition}
                      hasSubmittedCaclulation={hasSubmittedCalculation}
                    />
                  </TabPanel>
                  {(financingTypeId === 31 ||
                    financingTypeId === 53 ||
                    financingTypeId === fuelFinancingTypeId) && (
                    <TabPanel value={tabValue} index={servicesTabIndex}>
                      <FinancesServices
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        loading={loading}
                        serviceVariantsLoading={serviceVariantsLoading}
                        readonly={readonly || isServiceReadonly}
                        isDurationReadonly={isServiceDurationReadonly}
                        isMileageReadonly={isServiceMileageReadonly}
                        dealId={dealId}
                        calculationId={calculationId}
                        fuelServiceProducts={fuelServiceProducts}
                        serviceProducts={serviceProducts}
                        productIndusties={productIndusties}
                        productUsages={productUsages}
                        campaigns={campaigns}
                        productOptions={productOptions}
                        technicalOptions={technicalOptions}
                        serviceVariants={serviceVariants}
                        serviceVariantId={serviceVariantId ?? undefined}
                        onRelevantChange={handleRelevantChange}
                        onRequestServices={loadServiceData}
                        onCalculateServicePrice={calculateServicePrice}
                        onSaveServiceVariant={onSaveServiceVariant}
                        onCreateProposal={onCreateProposal}
                        onRequestSpecialCasePrice={onRequestSpecialCasePrice}
                        onCompleteSpecialCasePrice={onCompleteSpecialCasePrice}
                        sx={{ width: '100%', paddingBottom: '200px' }}
                      />
                    </TabPanel>
                  )}
                  <TabPanel value={tabValue} index={novaTabIndex}>
                    <FinancesNova
                      sx={{ width: '100%', paddingBottom: '200px' }}
                      control={control}
                      dealers={dealers}
                      suppliers={suppliers}
                      displayGross={displayGross}
                      onRelevantChange={handleRelevantChange}
                      loading={loading}
                      setValue={setValue}
                      readonly={readonly}
                      dealId={dealId}
                      calculationId={calculationId}
                      intermFiles={intermFiles}
                      setIntermFiles={setIntermFiles}
                    />
                  </TabPanel>
                </form>
              </Box>
            </Grid>
          </Grid>
          <FinancesCalculationFooter
            loading={loading}
            onClose={handleClose}
            control={control}
            vatFree={vatFree}
            width={width}
            readonly={readonly}
            isVote={isVote}
            disableSave={apiErrors !== undefined || !isCalcValid}
            onVote={onVote}
          />
        </Box>
      </Drawer>
    </div>
  );
};
