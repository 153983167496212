import React, { useEffect, useState } from 'react';
import RestHttpClient from '../../../common/RestHttpClient';
import { Box, CircularProgress, Grid } from '@mui/material';
import { OverviewListEntry } from './OverviewListEntry';
import { GlobalTextIdentifiers, useGlobalTitles } from '../../../common/global/useGlobalTitles';

interface Props {
  showAll: boolean;
  onFilterChange: (filter?: number | string) => void;
}

const DashboardSpecialOfferWidget = ({ showAll, onFilterChange }: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ [key: string]: number }>();
  const getTitle = useGlobalTitles();

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getSpecialOfferWidget(showAll);
      setData(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  return (
    <Grid item xs={6} lg={3}>
      <Box className="card">
        <h2 style={{ cursor: 'pointer' }} onClick={() => onFilterChange(undefined)}>
          {getTitle(GlobalTextIdentifiers.DashboardSpecialOffer)}
        </h2>
        <span className="line" />

        {data && !loading && (
          <>
            {Object.keys(data)
              .filter((k) => k !== '')
              .map((k) => (
                <OverviewListEntry
                  key={k}
                  title={k}
                  value={data[k]}
                  color="blue"
                  onClick={() => onFilterChange(k)}
                />
              ))}
          </>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {loading && <CircularProgress />}
        </Box>
      </Box>
    </Grid>
  );
};

export default DashboardSpecialOfferWidget;
