import { red } from '@mercedes-benz/mui5-theme/';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { IDocumentDetail, UploadedDocumentView } from '../../../generated/ApiClient';
import { Icons } from '../../../component/icons/Icons';
import { useTranslation } from 'react-i18next';
import { UploadInput } from '../../../component/UploadInput';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import RestHttpClient from '../../../common/RestHttpClient';
import { EditDealArchivedDocuments } from '../../deals/detail/documents/EditDealArchivedDocuments';
import { EditDealSignedDocuments } from '../../deals/detail/documents/EditDealSignedDocuments';
import { usePermissions } from '../../../common/auth/PermissionsContext';
import { tryUploadFiles, useDocumentUpload } from '../../../common/util/OcrUploadUtil';
import { FormDropdown } from '../../../component/form/FormDropdown';
import { UploadedDocuments } from '../../deals/detail/documents/UploadedDocuments';

interface Props {
  documentDetail?: IDocumentDetail;
  dealId?: number;
}

const schema = z.object({
  documentId: z.number(),
});
type UploadDocSchema = z.infer<typeof schema>;

export function OverlayDocuments({ documentDetail, dealId }: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [docs, setDocs] = useState<UploadedDocumentView[]>([]);

  const fetchDocuments = async () => {
    if (dealId) {
      try {
        setLoading(true);
        const { data } = await RestHttpClient.getUploadedDocuments(dealId);
        setDocs(data);
      } finally {
        setLoading(false);
      }
    }
  };

  const canViewSignatureDocs = hasPermission('SignatureDocument', 'View');
  const canViewArchivedDocs = hasPermission('ArchivedDocument', 'View');

  const { validateFile, removeChars, requiredDocs, allowedExtensions } = useDocumentUpload(dealId);

  const handleFileChange = async (file?: File) => {
    console.log('#handleFileChange file', file);
    setFile(file);
  };

  const cancelUpload = () => {
    setFile(undefined);
  };

  const { control, handleSubmit } = useForm<UploadDocSchema>({
    resolver: zodResolver(schema),
  });

  const resetUpload = async () => {
    await fetchDocuments();
    setFile(undefined);
    setLoading(false);
    setUploading(false);
  };

  const onSubmit = (event: FormEvent) => {
    handleSubmit(async (values) => {
      setLoading(true);
      setUploading(true);

      if (file && validateFile(file) && dealId)
        tryUploadFiles(values.documentId, false, dealId, resetUpload, removeChars, file);
    })(event);
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  return (
    <>
      <Box width="100%" mt={2} sx={{ pb: '200px' }}>
        {documentDetail?.missingDocuments && documentDetail?.missingDocuments.length > 0 && (
          <Box
            sx={{
              width: '100%',
              background: red['90'],
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
              padding: '16px',
            }}
          >
            <Box sx={{ mr: '16px' }}>{Icons.redInfo()}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle1">
                {t('dashboard.contractsOverlay.documents.missing')}
              </Typography>
              <ul>
                {documentDetail.missingDocuments.map((md) => (
                  <li>{md}</li>
                ))}
              </ul>
            </Box>
          </Box>
        )}

        <Dialog
          open={Boolean(file) && !uploading}
          onClose={cancelUpload}
          aria-labelledby="confirm-dialog"
        >
          <DialogTitle id="confirm-dialog">{t('deals.edit.documents.uploadConfirm')}</DialogTitle>
          <form onSubmit={onSubmit} noValidate>
            <DialogContent>
              <FormDropdown
                control={control}
                options={requiredDocs}
                label={t('deals.edit.documents.documentType')}
                name="documentId"
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={cancelUpload}>
                {t('cancel')}
              </Button>
              <Button variant="contained" type="submit" color="primary">
                {t('deals.edit.documents.confirm')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        {dealId && (
          <UploadInput
            onFileChange={handleFileChange}
            file={file}
            disabled={loading}
            accept={allowedExtensions}
          />
        )}
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('dashboard.contractsOverlay.documents.uploaded')}
        </Typography>

        {uploading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        )}

        {!uploading && (
          <>
            {dealId && (
              <UploadedDocuments docs={docs} loading={loading} setDocLoading={setLoading} />
            )}

            {dealId && canViewSignatureDocs && (
              <>
                <Typography variant="h5" sx={{ my: 2 }}>
                  {t('dashboard.contractsOverlay.documents.signed')}
                </Typography>
                <EditDealSignedDocuments dealId={dealId} />
              </>
            )}
            {dealId && canViewArchivedDocs && (
              <>
                <Typography variant="h5" sx={{ my: 2 }}>
                  {t('dashboard.contractsOverlay.documents.archived')}
                </Typography>
                <EditDealArchivedDocuments dealId={dealId} />
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
