import RestHttpClient from '../../../../common/RestHttpClient';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { EnhancedTable } from '../../../../common/table/EnhancedTable';
import { HeadCell } from '../../../../common/table/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import { AssignFleetRequest, FleetEntry } from '../../../../generated/ApiClient';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { useNavigate } from 'react-router';
import { DateTimeFormatter } from '../../../../common/formatter/DateTimeFormatter';

interface FleetEntryRow {
  id: string;
  offerId: string | undefined;
  createdAt: string;
  icon: string;
}

export function FleetSelecctionDialog({
  dealId,
  open,
  setOpen,
  entries,
  onCancel,
}: {
  dealId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  entries: FleetEntry[];
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<number>();
  const navigate = useNavigate();

  const onSelection = (selected: readonly string[]) => {
    if (selected.length) setSelected(parseInt(selected[0]));
    else setSelected(undefined);
  };

  const headCells: readonly HeadCell<FleetEntryRow>[] = [
    {
      id: 'id',
      disablePadding: false,
      label: t('deals.fleet.list.dealId'),
    },
    {
      id: 'offerId',
      disablePadding: false,
      label: t('deals.fleet.list.offerId'),
    },
    {
      id: 'createdAt',
      disablePadding: false,
      label: t('deals.fleet.list.createdAt'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const mappedRows: FleetEntryRow[] =
    entries.map((x) => ({
      id: String(x.dealId),
      offerId: x.offerId,
      createdAt: DateTimeFormatter.format(x.createdAt),
      icon: '',
    })) ?? [];

  const onSubmit = async () => {
    if (selected) {
      await RestHttpClient.assignFleet(
        new AssignFleetRequest({ sourceId: dealId, targetId: selected })
      );
      pushNotificationHandler.publish(t('deals.fleet.success'), 'success');

      navigate(`/deals/${selected}`);
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} PaperProps={{ sx: { maxWidth: 'unset' } }}>
      <DialogTitle>{t('deals.fleet.selectionTitle')}</DialogTitle>
      <DialogContent>
        <EnhancedTable
          rows={mappedRows}
          headCells={headCells}
          hideCheckbox={false}
          enableSelection
          enableSingleSelection
          onSelection={(_, sel) => onSelection(sel)}
          defaultSelected={selected ? [selected.toString()] : []}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('deals.fleet.cancelSelect')}</Button>
        <Button onClick={onSubmit} disabled={!selected} variant="contained">
          {t('deals.fleet.select')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
