import { SalesAidLimitViolationView } from '../../../../../../generated/ApiClient';
import i18n from '../../../../../../common/i18n/i18n';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  violations: SalesAidLimitViolationView[];
  recipientUnits: { [index: number]: string };
  ksvRating: number | undefined | null;
  roracUrl: string | undefined | null;
}

const ExtendedConfigurationViolations = ({
  violations,
  recipientUnits,
  ksvRating,
  roracUrl,
}: Props) => {
  const { t } = useTranslation();

  if (!violations.length) return null;

  return (
    <Stack flexDirection="column" gap={3}>
      <Typography variant="h4">
        {t('deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.violation')}
      </Typography>
      <Stack flexDirection="row" gap={2}>
        <TextField
          variant="standard"
          label={t('deals.new.financesConfiguration.calculationDrawer.ksvRating')}
          value={ksvRating}
          disabled
        />
        <TextField
          sx={{ flexGrow: 1 }}
          disabled
          variant="standard"
          value={roracUrl}
          label={t('deals.new.financesConfiguration.calculationDrawer.roracUrl')}
        />
        <Button
          href={roracUrl ?? ''}
          disabled={!roracUrl}
          target="_blank"
          sx={{ whiteSpace: 'nowrap', mt: 1 }}
        >
          {t('deals.new.financesConfiguration.calculationDrawer.openRorac')}
        </Button>
      </Stack>
      <Stack gap={1}>
        {violations.map((x, i) => (
          <>
            <SalesAidLimitViolation key={i} violation={x} recipientUnits={recipientUnits} />
            <Divider />
          </>
        ))}
      </Stack>
    </Stack>
  );
};

function SalesAidLimitViolation({
  violation,
  recipientUnits,
}: {
  violation: SalesAidLimitViolationView;
  recipientUnits: { [index: number]: string };
}) {
  const { t } = useTranslation();
  return (
    <Stack p={1} flexDirection="row" gap={2} justifyContent="flex-start">
      {violation.subsidyRecipient ? (
        <Typography>
          {i18n.t([
            `deals.new.financesConfiguration.calculationDrawer.subsidyValues.${violation.subsidyRecipient}`,
            '',
          ])}
        </Typography>
      ) : (
        <Typography>{violation.info}</Typography>
      )}
      {violation.initialValue && (
        <Typography>
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.initialValue', {
            value: toSubsidyDisplayText(
              violation.initialValue,
              violation.subsidyRecipient,
              recipientUnits
            ),
          })}
        </Typography>
      )}
      {violation.limit && (
        <Typography>
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.limit', {
            value: toSubsidyDisplayText(
              violation.limit,
              violation.subsidyRecipient,
              recipientUnits
            ),
          })}
        </Typography>
      )}
      {violation.subsidySum && (
        <Typography>
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidySum', {
            value: toSubsidyDisplayText(
              violation.subsidySum,
              violation.subsidyRecipient,
              recipientUnits
            ),
          })}
        </Typography>
      )}
      {violation.minimum && (
        <Typography>
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.minimum', {
            value: toSubsidyDisplayText(
              violation.minimum,
              violation.subsidyRecipient,
              recipientUnits
            ),
          })}
        </Typography>
      )}
      {violation.minKsv && violation.maxKsv && (
        <Typography>
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.ksvRange', {
            min: violation.minKsv,
            max: violation.maxKsv,
          })}
        </Typography>
      )}
    </Stack>
  );
}

export function toSubsidyDisplayText(
  value: number | undefined,
  recipientId: number | undefined,
  recipientUnits: { [index: number]: string }
) {
  if (value === undefined || recipientId === undefined) return '';

  return `${value.toLocaleString(
    'de-DE',
    recipientUnits[recipientId] !== 'Monat(e)'
      ? {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : undefined
  )} ${recipientUnits[recipientId]}`;
}

export default ExtendedConfigurationViolations;
