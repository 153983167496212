import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { BACK, CONFIRM_FINANCES } from '../state/orderStateMachine';
import { FinancesConfiguration } from './FinancesConfiguration';
import { FinancesCalculationList } from './FinancesCalculationList';
import { FinancesCalculationContextValues } from '../../common/financeOverlay/RightDrawerSchema';
import { NewDealFinanceDrawer } from './NewDealFinanceDrawer';

const schema = z.object({
  finances: z.string().optional(),
  calculationBasisInclVAT: z.number().nonnegative(),
  calculationBasisExclVAT: z.number().nonnegative(),
  listPrice: z.number().nonnegative(),
  purchasePrice: z.number().nonnegative(),
  discountCategory: z.number().nonnegative().optional(),
  businessType: z.number().nonnegative(),
  pricesIncludingVAT: z.boolean(),
});

export type FinancesFormValues = z.infer<typeof schema>;

export function FinancesForm() {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [editObject, setEditObject] = useState<FinancesCalculationContextValues | undefined>(
    undefined
  );
  const stateMachine = useContext(StateMachineContext);
  const [state, send] = useActor(stateMachine);

  const { t } = useTranslation();

  useEffect(() => {
    console.log(isDrawerOpened);
    if (!isDrawerOpened) {
      setEditObject(undefined);
    }
  }, [isDrawerOpened]);

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FinancesFormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: state.context.finances,
  });

  const onSubmit = (event: FormEvent) =>
    handleSubmit((values) => {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          send({
            type: CONFIRM_FINANCES,
            info: values,
          });
          console.log(
            'most up-to-date values: ' + JSON.stringify(stateMachine.getSnapshot().context)
          );

          resolve();
        }, 1000);
      });
    })(event).catch((err) => {
      console.log(err);
      // TODO Set validation errors here
      setError('finances', {
        type: 'custom',
        message: 'invalid',
      });
    });

  const handleRowClick = (values: FinancesCalculationContextValues) => {
    setEditObject(values);
    setIsDrawerOpened(true);
  };

  const handleNew = () => {
    setEditObject(undefined);
    setIsDrawerOpened(true);
  };

  return (
    <>
      <div className="stepper-footer">
        <Button disabled={isSubmitting} className="white" onClick={() => send(BACK)}>
          {t('back')}
        </Button>
        <h2 className="current-step">{t('deals.new.stepFinancesSubtitle')}</h2>
        <Button variant="contained" disabled={isSubmitting} type="submit" form="finances-form">
          {t('continue')}
        </Button>
      </div>
      <Divider />
      <div className="content">
        <div className="form" style={{ width: '100%' }}>
          <form
            onSubmit={onSubmit}
            id="finances-form"
            noValidate
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <FinancesConfiguration />
            <Divider orientation="horizontal" />
            <FinancesCalculationList
              onRowClick={handleRowClick}
              onNew={handleNew}
              items={state.context.finances?.calculations ?? []}
              vehicleAgeIsValid={state.context.model?.vehicleAgeIsValid ?? true}
            />
            <NewDealFinanceDrawer
              key="1"
              editObject={editObject}
              isDrawerOpened={isDrawerOpened}
              setIsDrawerOpened={setIsDrawerOpened}
              isIncludingVat={state.context.model?.pricesIncludingVAT ?? false}
            />
          </form>
        </div>
      </div>
    </>
  );
}
