import { Info } from '@mui/icons-material';
import { Box, BoxProps, Button, Divider, InputAdornment, Tooltip } from '@mui/material';
import { FormEvent } from 'react';
import { Control, UseFormGetValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';
import { FormNumberInput } from '../../../../../component/form/FormNumberInput';
import {
  ApiconPriceFormUiState,
  ApiconSpecialCasePriceFormState,
} from '../../../../../generated/ApiClient';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { ServiceVariant } from './FinancesServiceVariant';

type Props = {
  control: Control<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  loading: boolean;
  readonly?: boolean;
  dealId?: number;
  calculationId?: number;
  serviceVariants: ServiceVariant[];
  serviceVariantId: number | undefined;
  specialCasePriceFormState: ApiconSpecialCasePriceFormState;
  onCalculateServicePrice: () => void;
  onSaveServiceVariant: (event: FormEvent, isServiceVariantSaveRequest: boolean) => void;
  onSpecialCasePriceButtonClicked: () => void;
  onCompleteSpecialCasePrice: () => void;
} & BoxProps;
export const FinancesServiceCalculation = ({
  control,
  getValues,
  loading,
  readonly,
  dealId,
  calculationId,
  serviceVariants,
  serviceVariantId,
  specialCasePriceFormState,
  onCalculateServicePrice,
  onSaveServiceVariant,
  onSpecialCasePriceButtonClicked,
  onCompleteSpecialCasePrice,
  ...others
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEditServices = hasPermission('Services', 'Edit') && !readonly;
  const isCalculationSaved = calculationId !== undefined && calculationId > 0;
  const isServiceVariantSelected = serviceVariantId !== undefined;
  const specialCaseRequestStatusReceived = () => {
    const specialCasePriceFormUiState = getValues().service.apiconSpecialCase?.priceFormUiState;
    const specialCasePriceFormState = getValues().service.apiconSpecialCase?.priceFormState;
    return (
      (specialCasePriceFormUiState === ApiconPriceFormUiState.SpecialCaseRequestApproved ||
        specialCasePriceFormUiState === ApiconPriceFormUiState.SpecialCaseRequestRejected) &&
      (specialCasePriceFormState === ApiconSpecialCasePriceFormState.SpecialCaseRequestApproved ||
        specialCasePriceFormState === ApiconSpecialCasePriceFormState.SpecialCaseRequestRejected)
    );
  };
  const apiconSpecialCaseStateText = () => {
    if (
      getValues().service.apiconSpecialCase?.priceFormUiState ===
      ApiconPriceFormUiState.SpecialCaseRequestInProgress
    )
      return t(
        'deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.inProgress'
      );
    if (
      specialCaseRequestStatusReceived() &&
      getValues().service.apiconSpecialCase?.priceFormState ===
        ApiconSpecialCasePriceFormState.SpecialCaseRequestApproved
    )
      return t(
        'deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.approved'
      );
    if (
      specialCaseRequestStatusReceived() &&
      getValues().service.apiconSpecialCase?.priceFormState ===
        ApiconSpecialCasePriceFormState.SpecialCaseRequestRejected
    )
      return t(
        'deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.rejected'
      );
  };
  const getPriceNetName = () => {
    return displaySpecialPriceCreateButtonAndShowPrice()
      ? 'service.apiconSpecialCase.priceNet'
      : 'service.apiconPriceForm.priceNet';
  };
  const getPriceGrossName = () => {
    return displaySpecialPriceCreateButtonAndShowPrice()
      ? 'service.apiconSpecialCase.priceGross'
      : 'service.apiconPriceForm.priceGross';
  };
  const specialCasePriceFormUiState = useWatch({
    control,
    name: 'service.apiconSpecialCase.priceFormUiState',
  });
  const apiconPriceFormPriceFormState = useWatch({
    control,
    name: 'service.apiconPriceForm.priceFormState',
  });
  const displaySpecialPriceCreateButtonAndShowPrice = () => {
    return (
      specialCasePriceFormUiState === ApiconPriceFormUiState.SpecialCaseRequestDisplayPrice ||
      specialCasePriceFormUiState === ApiconPriceFormUiState.SpecialCaseRequestFinishedWithPrice
    );
  };
  const displaySpecialCasePriceInitialView = () => {
    return (
      apiconPriceFormPriceFormState === ApiconSpecialCasePriceFormState.SpecialCasePrice &&
      (specialCasePriceFormUiState === ApiconPriceFormUiState.SpecialCaseRequestIsPriceOnRequest ||
        specialCasePriceFormUiState ===
          ApiconPriceFormUiState.SpecialCaseRequestFinishedIsPriceOnRequest)
    );
  };

  return (
    <Box
      sx={{
        width: 1 / 2,
        p: 2,
        pr: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
      {...others}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {!displaySpecialCasePriceInitialView() ? (
          <>
            <Box sx={{ ml: '8%' }}>
              <FormNumberInput
                control={control}
                name={getPriceGrossName()}
                label=""
                helperText={t(
                  'deals.new.financesConfiguration.calculationDrawer.rate.rateIncludingVAT'
                )}
                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                shrink
                disableUnderline
                disabled={true}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ ml: '8%' }}>
              <FormNumberInput
                control={control}
                name={getPriceNetName()}
                defaultValue={0}
                label=""
                helperText={t(
                  'deals.new.financesConfiguration.calculationDrawer.rate.rateExcludingVAT'
                )}
                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                shrink
                disableUnderline
                disabled={true}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ alignItems: 'flex-end', ml: '8%' }}>
              {displaySpecialPriceCreateButtonAndShowPrice() ? (
                <FormNumberInput
                  control={control}
                  name="service.apiconSpecialCase.vatAsValue"
                  label=""
                  helperText={t('deals.new.financesConfiguration.calculationDrawer.rate.VAT')}
                  startAdornment={<InputAdornment position="start">€</InputAdornment>}
                  shrink
                  disableUnderline
                  disabled={true}
                />
              ) : (
                <FormNumberInput
                  control={control}
                  name="service.apiconPriceForm.vatAsValue"
                  label=""
                  helperText={t('deals.new.financesConfiguration.calculationDrawer.rate.VAT')}
                  startAdornment={<InputAdornment position="start">€</InputAdornment>}
                  shrink
                  disableUnderline
                  disabled={true}
                />
              )}
            </Box>
          </>
        ) : (
          <Box>{t('deals.new.financesConfiguration.calculationDrawer.options.priceOnRequest')}</Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '150px',
          justifyContent: 'flex-end',
        }}
      >
        {canEditServices && (
          <Button
            onClick={onCalculateServicePrice}
            disabled={
              !canEditServices ||
              loading ||
              getValues().service.apiconProposalForm?.proposalCreationDate != null
            }
            variant="contained"
            type="button"
            sx={{
              height: '28px',
              width: '100px',
              alignSelf: 'flex-end',
              whiteSpace: 'nowrap',
              minWidth: 'auto',
            }}
          >
            {t('deals.new.financesConfiguration.calculationDrawer.options.priceInquiry')}
          </Button>
        )}
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '2%' }}>
          {dealId === undefined && (
            <Tooltip
              placement="right"
              title={t('deals.new.financesConfiguration.calculationDrawer.options.saveVariantHint')}
            >
              <Info color="primary" />
            </Tooltip>
          )}
          <Button
            onClick={(event) => onSaveServiceVariant(event, true)} // TODO Valierungen wie im alten
            disabled={
              !canEditServices ||
              loading ||
              dealId === undefined ||
              getValues().service.apiconProposalForm?.proposalCreationDate != null
            }
            sx={{
              height: '28px',
              width: '169px',
              border: 1,
              whiteSpace: 'nowrap',
              minWidth: 'auto',
            }}
          >
            {t('deals.new.financesConfiguration.calculationDrawer.options.saveAsVariant')}
          </Button>
          {getValues().service.apiconPriceForm?.priceFormState ===
            ApiconSpecialCasePriceFormState.SpecialCasePrice && (
            <Box>
              <Box>{apiconSpecialCaseStateText()}</Box>
              {(displaySpecialPriceCreateButtonAndShowPrice() ||
                displaySpecialCasePriceInitialView()) && (
                <Button
                  disabled={
                    !canEditServices ||
                    loading ||
                    !isCalculationSaved ||
                    !isServiceVariantSelected ||
                    getValues().service.apiconProposalForm?.proposalCreationDate != null ||
                    serviceVariants.length < 1
                  }
                  onClick={onSpecialCasePriceButtonClicked}
                  sx={{
                    height: '28px',
                    width: '269px',
                    border: 1,
                    whiteSpace: 'nowrap',
                    minWidth: 'auto',
                  }}
                >
                  {t(
                    'deals.new.financesConfiguration.calculationDrawer.options.specialPriceRequest'
                  )}
                </Button>
              )}
              {specialCaseRequestStatusReceived() && (
                <Button
                  disabled={loading || !isCalculationSaved || !isServiceVariantSelected}
                  onClick={onCompleteSpecialCasePrice}
                  sx={{
                    height: '28px',
                    width: '269px',
                    border: 1,
                    whiteSpace: 'nowrap',
                    minWidth: 'auto',
                  }}
                >
                  {t(
                    'deals.new.financesConfiguration.calculationDrawer.options.completeSpecialPriceRequest'
                  )}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
