import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { NotificationView } from '../../generated/ApiClient';
import { t } from 'i18next';
import React from 'react';

interface NotificationModalHandler {
  confirm: (callback: Function, notifications: NotificationView[]) => void;
}

interface NotificationModalState {
  visible: boolean;
}

export let notificationHandler: NotificationModalHandler;

export class NotificationModal
  extends React.Component<{}, NotificationModalState>
  implements NotificationModalHandler
{
  private callback: Function | undefined;
  private notifications: NotificationView[];

  constructor(props: {}) {
    super(props);
    this.notifications = [];

    this.state = { visible: false };
    notificationHandler = this;
  }

  public confirm(callback: Function, notifications: NotificationView[]): void {
    this.callback = callback;
    this.notifications = notifications;
    this._open();
  }

  private _open = () => {
    this.setState({ visible: true });
  };

  private _close = () => {
    this.setState({ visible: false });
  };

  private _confirm = () => {
    if (this.callback) {
      this.callback();
    }
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;

    return (
      <Dialog open={visible} aria-labelledby="confirm-dialog" onClose={this._close}>
        {/* <DialogTitle id="confirm-dialog">{this.title}</DialogTitle> */}
        <DialogContent>
          <Stack gap={2} minWidth="200px">
            {this.notifications.map((x) => (
              <div key={x.id} dangerouslySetInnerHTML={{ __html: x.content }} />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this._confirm} color="primary">
            {t('omit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
