import { z } from 'zod';
import {
  doubleOrNil,
  radioButton,
  requiredInteger,
  requiredPosDouble,
} from '../../../../common/validation/Validations';

export const schema = z
  .object({
    bmLevel: z.number().nonnegative(),
    enableBMLevel: z.boolean(),
    liability_BonusMalus_Internal_Id: z.number().nonnegative().nullish(),
    usageTypeId: z.number().nonnegative().nullable(),
    isPKW: z.boolean(),
    isCo2Required: z.boolean(),
    kw: requiredPosDouble,
    co2: doubleOrNil,
    weight: doubleOrNil,
    numberOfSeats: requiredInteger,
    maxTotalWeight: doubleOrNil,
    applicantId: z.number(),
    additionalApplicantId: z.number().optional().nullable(),
    paymentPeriodId: z.number().nonnegative(),
    paymentTypeId: z.number().nonnegative(),
    specialOfferId: z.number().optional().nullable(),

    insuranceCompanySelectionEnabled: z.boolean(),
    enableLiability: z.boolean(),
    liabilityInsuranceCompanyId: z.number().nonnegative().optional().nullable(),
    liabilityInsuranceId: z.number().optional().nullable(),
    mayShowLegalProtectionInsurance: z.boolean(),
    enableLegalProtection: z.boolean(),
    mayShowAccidentInsurance: z.boolean(),
    enableAccident: z.boolean(),

    enableKasko: z.boolean(),
    kaskoInsuranceCompanyId: z.number().optional().nullable(),
    kaskoInsuranceId: z.number().optional().nullable(),
    kaskoAdditionalProductIds: z.array(z.number()),
    inputTaxDeduction: radioButton,
    enableOmdsFields: z.boolean(),
    externalCalculationNumber: z.string().optional().nullable(),
    externalCalculationInfo: z.string().optional().nullable(),
    liabilityPremiumDiscount: doubleOrNil,
    kaskoPremiumDiscount: doubleOrNil,

    engineTax: doubleOrNil,
    kaskoPrice: doubleOrNil,
    liabilityPrice: doubleOrNil,
    additionalProductsPremium: doubleOrNil,
    totalPrice: requiredPosDouble,
    accidentPremium: doubleOrNil,
    legalProtectionPremium: doubleOrNil,
    commissionTotal: doubleOrNil,
    removeCommission: z.boolean().optional().nullable(),
    isMBCar: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.usageTypeId == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Pflichtfeld',
        fatal: false,
        path: ['usageTypeId'],
      });
      return z.NEVER;
    }
    if (data.enableLiability) {
      if (data.liabilityInsuranceCompanyId == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Pflichtfeld',
          fatal: false,
          path: ['liabilityInsuranceCompanyId'],
        });
        return z.NEVER;
      }
      if (data.liabilityInsuranceId == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Pflichtfeld',
          fatal: false,
          path: ['liabilityInsuranceId'],
        });
        return z.NEVER;
      }
    }
    if (data.enableKasko) {
      if (data.kaskoInsuranceCompanyId == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Pflichtfeld',
          fatal: false,
          path: ['kaskoInsuranceCompanyId'],
        });
        return z.NEVER;
      }
      if (data.kaskoInsuranceId == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Pflichtfeld',
          fatal: false,
          path: ['kaskoInsuranceId'],
        });
        return z.NEVER;
      }
    }
    if (data.isCo2Required) {
      if (data.co2 == null || data.co2 < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Pflichtfeld',
          fatal: false,
          path: ['co2'],
        });
        return z.NEVER;
      }
    }
    if (data.paymentPeriodId === 1 && data.paymentTypeId === 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'bei monatlicher Zahlung darf nicht mit Zahlschein gezahlt werden',
        fatal: false,
        path: ['paymentTypeId'],
      });
    }
  });

export type InsuranceFormValues = z.infer<typeof schema>;
